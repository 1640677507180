.checkout-progress-indicator {
    overflow: hidden;
    div {
        display: inline;
        padding: 0 .83em 0 0;
    }
    span {
        text-transform: uppercase;
    }
    .active span {
        font-family: $Medium;
        color: $black;
    }
    a {
        color: $black;
    }
    i {
        padding-left: .83em;
        color: $tan;
    }
    .step-3 {
        padding-right: 0;
    }
}

.gift-message-text {
    overflow: visible;
    opacity: 1;
    transition: all 1s ease;
    &.hidden {
        height: 0;
        opacity: 0;
        overflow: hidden;
    }
    textarea {
        width: 100%;
    }
}

.billing-coupon-code {
    .form-row,
    .coupon-apply,
    .giftcert-apply {
        display: inline-block;
    }
    
    .coupon-apply {
        vertical-align:bottom;
        margin-bottom:0.9em;
    }
    .form-row {
        margin: 0;
        width: 45%;
        vertical-align:bottom;
        &.coupon-success {
            display:block;
            color:#28A873;
        }
    }
    .tooltip {
        padding-left: 1rem;
        border: #c10f3a;
    }
}

.redemption {
    .success {
        color: $green;
    }
    color: $error-red;
    span.error {
        margin-left: 0;
    }
}

.payment-method-options, .checkout-billing-ordertype {
    .form-row {
        clear: none;
        float: left;
        width: 30%;
    }
}

.payment-method {
    clear: both;
    display: none;
}

.payment-method-expanded {
    display: block;
}

.month,
.year,
.day {
    clear: none;
    float: left;
    margin: 0 0 2% 2%;
    label {
        @extend .visually-hidden;
        position: relative!important;
    }
    select {
        width: auto;
    }
}

.month .form-caption {
    margin-left: 0;
}

.cvn {
    clear: left;
    .field-wrapper {
        width: 70px;
    }
}

.ssn {
    clear: left;
}

.bml-terms-and-conditions {
    border: 1px solid $gray;
    font-size: 0.8em;
    height: 400px;
    overflow: auto;
    padding: 1em;
}

.bml-table-col-1 {
    width: 25%;
}

.pt_checkout h3 {
    padding-bottom: 1em;
}

.checkout-mini-cart {
    background-color: $white-alt;
    max-height: 330px;
    overflow: auto;
}

.checkout-order-totals {
    background-color: $white-alt;
    padding: 0.3rem .5rem;
}

.place-order-totals {
    clear: both;
    .order-totals-table {
        float: right;
        width: 50%;
    }
    .content-asset {}
}

.order-component-block {
    margin: 1.67em 0;
    &.placeorderpage {
        margin-top: 0px;
    }
    .section-header a {
        float: right;
        font-weight: normal;
    }
    .details {
        background: none repeat scroll 0 0 $white-alt;
        color: $gray;
        padding: 0.5em 1em;
    }
}
.order-component-block-mini {
    background: none repeat scroll 0 0 $white-alt;
    width: 270px !important;
    border-radius: 7px;
    height:180px;
    &.placeorderpage {
        margin-top: 0;
    }
    .section-header a {
        float: right;
        font-weight: normal;
    }
}
.order-summary-footer {
    position: relative;
    h2 {
        font-size: 2em;
        font-weight: normal;
    }
}

.summary-help {
    float: right;
    font-size: 0.8em;
    margin: 1em 0;
}

.submit-order {
    clear: both;
    display: block;
    padding: 1.67em 0 0;
    text-align: right;
    .back-to-cart {
        font-size: 1.1em;
        &:hover {
            text-decoration: underline;
        }
        @include breakpoint(small) {
            float: left;
            margin-top:7px;
        }
    }
    button {
        margin-left: 1.5em;
    }
}

.order-discount {
    color: $error-red;
}

.gift-cert-used {
    padding: .83em 0;
}

.summary .edit {
    position: absolute;
    text-transform: uppercase;
    right: 10px;
    font-size: 0.9em;
    line-height: 1.5em;
}

.pt_checkout #main {
    padding: 20px 1.15em;
}

.pt_cart #main {
    padding: 0 1.15em;
}

.pt_cart .breadcrumb {
    padding-left: 0;
}

.item-shipping-address {
    min-width: 120px;
}

.billing-error {
    color: red;
    margin: 0 0 .83em 24%;
}

.cvn-tooltip {
    font-family: $Bold;
    margin: 5px;
    text-align: center;

    img {
        height: 150px;
        width: 110px;
    }
}

#shipping-method-list {
    position: relative;
	.standard-shipping {
		text-decoration:line-through;
	}
	.discount-shipping {
		color:$error-red;
	}
}

/*** This will hide the Email Opt-In temporarily ***/
.pt_checkout {
	.checkout-billing {
		.email-optin {
			display:none;
		}
	}
}

.payment-method {
    .form-row.error {
        .form-caption.error-message {
            border:0px;
            background: transparent;
            padding:0;
        }
        
        .input-text,.input-select {
            border: 2px solid #c10f3a;
        }
    }
    .expiration-container {
        position:relative;
        .form-row.error {
            label {
                color: #c10f3a;
            }
            
            .form-caption.error-message {
                position:absolute;
                width:49.5966%;
                left:0px;
                top:85px;
                border:0px;
                background: transparent;
            }
        }
    }
}


.order-round-up-donation {
    .switch {
        position: relative;
        display: inline-block;
        width: 43px;
        height: 15px;
        margin-top: 5px;
        input {display:none;}

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #bdbdbd;
          -webkit-transition: .4s;
          transition: .4s;
        }
        
        .slider:before {
          position: absolute;
          content: "";
          height: 20px;
          width: 20px;
          left: 0px;
          bottom: 0px;
          top: -4px;
          border: 1px solid #ccc;
          background-color: #f6f6f6;
          -webkit-transition: .4s;
          transition: .4s;
        }
        
        input:checked + .slider {
          background-color: #8FC6D8;
        }
        
        input:focus + .slider {
          box-shadow: 0 0 1px #2196F3;
        }
        
        input:checked + .slider:before {
          -webkit-transform: translateX(21px);
          -ms-transform: translateX(21px);
          transform: translateX(21px);
          background-color: #347DA3;
          border: 1px solid #347DA3;
        }
        
        /* Rounded sliders */
        .slider.round {
          border-radius: 34px;
        }
        
        .slider.round:before {
          border-radius: 50%;
        }
    }
}

.address-create.button {
	display: none;
}

.mini_address_ship_show{
    width:100%;
    float:left;
}

.minishipmodal a{
    background-color: #0E6C99;
    border-color: #0a4b6a;
    border-radius: 4px;
    border: 1px solid #0E6C99;
    color: white !important;
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    font-family: "StJude Regular", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 0.5em 2em;
    outline: none;
    text-align: center;
    transition: all 0.3s ease;
    margin: 2px 0 0 70px;
}

.ship_bill_responsive {
  width: 300px;
  float: left;
}

.hidebillingAddressCard{
    display: none;
}

.ship_head_align{
	padding-left:20px; 
	padding-top:8px; 
	border-bottom: 
	2px solid #fff;
}

.pad_shipbill_body{
    padding-left:20px;
    padding-bottom:15px
    }


    .card-input-element {
        display: none;
        position: relative;
    }
    
    .card-input {
        position:relative;
        display:table;
        padding: 15px 15px;
        width: 350px;
        height: 110px;
        border-radius:6px;
    }
    
    .card-input:hover {
        cursor: pointer;
    }
    
    .card-input-element:checked + .card-input {
         border: 3px solid #2F82DF;
     }
    
     .card-input-element + .card-input {
        border: 3px solid darkgrey;
     }
    
    .modal_btn_height{
        padding-top:1.4em;
        margin-top:13px; 
        text-align:center;
    }
    
    .unable_verify{
        margin:40px 100px;
        text-align: center;
        font-size:13px;
        font-style: italic;
    }
    
    .recom_use {
        margin:30px 50px!important;
        font-size:33px !important;
        text-align: center !important;
        font-style: italic;
    }
    
    .ui-dialog{
        border-radius:10px;
    }
    
    .ship_mod_h3{
        padding-left:79px;
        text-align: left;
        font-style: italic;
    }
    
    .card-input-element:checked + .card-input i {
        color: #2F82DF !important;
    }
    
    .card-input {
        .inner_content{
            width: 80%;
            display: table-cell;
            vertical-align: middle;
            line-break: anywhere;
            padding-left: 15px;
            font-size: 16px;
            line-height: 30px;
        }
    }
    
    .card-input .fa.fa-home {
      font-size: 60px;
      color: grey;
      width: 20%;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
    }
    
    .shipnbillheader{
        background-color: #F9FAFB;
        width: 91%;
        text-transform: uppercase;
        padding-left: 20px;
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .input_custom .form-row select{
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAWCAYAAADafVyIAAAAzElEQVRIDWP8//8/Ay0BE8zws2fPgmyium1wC2AWUZsetYBgiI4GEcEgYiGoAqqgq6trGZAZia4emFGXlZeXR6OLw/hEx8G/f/+ygYY9hWkE0UD+IyCVhSyGzibagoqKiveMjIyJIHNBhgAN/wekYoGu/wji4wJEWwAyoKysbDfQ4ClQw7qAhh/CZTBMnOg4gGl4+fJluYSEhNC9e/fqYGJ4aaCLQN5lOHPmDJACAzAfyKIKTVIQ4XUpDslRC3AEDEJ46AcRIyg50hIAANnugKRDswA6AAAAAElFTkSuQmCC");
        background-repeat: no-repeat;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-position-x: 99%;
        background-position-y: 10px;
    }

    .ml{
        margin-left:15px
    }
    
    .mr{
    margin-right:15px
    }
    .input_custom select {
        background-color: #fff;
        width: 100%;
        height: 2.8rem;
        border-radius:3px;
    }

    .coup_payment_header{
        height: 28px;
        padding-top: 10px;
        font-size: 15px;
        font-family: 'StJude Medium';
        width: auto;
        background-color: #F9FAFB;
        padding-left: 15px;
        margin-bottom: 25px;
        width: 91%;
    }

    .emailwidth{
        width:86%
    }

    .form_respon{
        display: inline-flex;
        width: 100%;
    }

    .avs-container {
        text-align: center;
        min-height: 700px;
        .card-input {
            margin: auto!important;
            text-align: left;
            font-family: $Regular!important;
        }

        #btn_recomended{
            background-color: #1874DB!important;
            font-size: 0.9rem!important;
        }
        #btn_entered{
            font-size: 0.9rem!important;
        }
    }

    .home_font{
        font-size:65px; 
        color:grey
    }
	
.pricealignment{
	float:right;
	padding-right:13%
}

.radio-toolbarship input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
  padding-top:50px;
  padding-left:20px;
}

.radio-toolbarship label {
	display: inline-block;
	border: 3px solid #f0f0f0;
	outline-color: #f9f9f9;
	outline-width: thin;
	width:680px !important;
	height:72px;
	border-radius:5px;
	padding-left:25px;
	padding-top:20px;
	margin:0;
}

.radio-toolbarship{
	margin-bottom:0 !important;
	}

.radio-toolbarship input[type="radio"]:checked + label {
	display: inline-block;
    width:680px !important;
	height:72px;
	border: 3px solid #135cb0;
	border-radius:5px;
	margin-bottom:0 !important;
	position:relative;
}

.radio-toolbarship input[type="radio"]:checked + label::after {
    content: "\f058";
    font-family: FontAwesome;
    color: #135cb0;
    font-style: normal;
    font-weight: normal;
    position: absolute;
    font-size: 25px;
	right: -0.3rem;
	top: -0.5rem;
}

.radio-toolbarship input[type="radio"]:checked + label i {
    color:#135cb0 !important;
}

.form-row{
	margin-bottom:0 !important;
}

.checkmark {
  position: relative;
}

.fa.fa-check-circle.tickmark-icon {
  position: absolute;
  right: -2px;
  top: -6px;
  font-size: 25px;
}

.qustalign{
	float: right;
    padding-right: 15px;
}

.labelalgn{
	padding-top:0.625rem;
}

.fldset{
	margin-bottom:50px;
}

.dlyopt{
	font-size:17px
}

.fontq{
	font-size:24px;
}

.tltspace{
	font-size:14px;
	letter-spacing:1;
}

.deliverystat{
	padding-left:15px;
	font-size:14px;
}
.deliverystat-normal{
	font-size:14px;
}

.icon-ft{
	font-size:26px
}
.shipping_header {
    background-color: #F9FAFB;
    width: 91%;
    text-transform: uppercase;
    padding-left: 20px;
    padding-top: 11px;
    padding-bottom: 1px;
	height: 38px;
	width:97%;
}

.margin_for_ship{
    margin-bottom:10px !important;
}

#btn_recomended{
    margin-bottom: 13px;
}
    @media only screen and (max-width: 600px) {
        .card-input {
            width: 76%;
            position: relative;
            .inner_content{
                font-size: 0.8rem!important;
            }
        }
        .form_respon{
            display: inline-block;
            width: 100%;
        }
    
        .checkout-shipping .tooltip {
            position: relative !important;
            padding-left: 15px;
            display: flex;
        }

        .checkout-billing .tooltip{
            padding-left: 15px;
            display: inherit;
        }

        .emailwidth{
            width:100%
        }

        .entered-add{
            text-align:center;
            margin-bottom:15px;
        }
    
        .unable_verify {
            margin: 40px 80px !important;
        }
    
        .card-input {
        margin:auto!important;
        z-index: -1;
        display: table;
        position: relative;
        max-width: 30rem;
        padding: 1em 1em 1em 0;
        }
    
        .ship_mod_h3{
            padding-left:35px !important;
            text-align: left;
        }
    
        .recom_use {
            margin: 30px 50px !important;
            font-size: 26px !important;
        }

        .mini_address_ship_show{
            width:100% !important;
            float:left;
        }
    
        .ship_bill_responsive {
            width: 70% !important;
            float: none !important;
          }

          .card-input .fa.fa-home {
            padding-left: 15px;
          }
		
		.radio-toolbarship input[type="radio"]:checked + label {
			width: 92% !important;
			height:120px;
		}

		.radio-toolbarship input[type="radio"] {
			width: 92% !important;
			height:120px;
		}

		.radio-toolbarship label {
			width: 92% !important;
			height:120px;
		}
		.fa.fa-check-circle.tickmark-icon{
			right:-5px;
		}
		.radio-toolbarship{
			margin-bottom:5px;
		}
		.label-inline .field-wrapper{
			width:100% !important;
		}
        .useasbillingcss,
        .checkboxAlignement {
            .field-wrapper{
                width: fit-content !important;
            }
        }

        .label-inline .cccard{
            width: 5% !important;
        }
		.btntxtalgncnt{
			text-align:center;
		}
		.labelalgn{
			padding-top:1.4rem;
		}
        .btntxtalgncnt{
			text-align:center;
		}
        .btntxtalgncnt .btn{
            padding : 15px 45px;
            letter-spacing: 1;
            font-size: 15px;
        }
    }