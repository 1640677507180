.ui-widget-overlay {
	background: $ui-overlay;
}

.ui-dialog {
	background-color: $white;
	padding: 0;
	.ui-widget-header {
		background-color: $white-alt;
		border: 0 none;
		border-radius: 0 0 0 0;
		height: 23px;
		text-transform: uppercase;
	}
	.ui-dialog-title {
		color: $black;
		font-family: $Bold;
	}
	.ui-icon-closethick {
		background-image: url("../images/interface/icon-ui-close.png");
		background-position: left top;
		height: 32px;
		width: 32px;
		left: 0;
		top: 0;
		margin-left: 0;
		margin-top: 0;
	}
	.ui-dialog-titlebar-close {
		background-color: transparent;
		border: 0 none;
		border-radius: 0 0 0 0;
		height: 32px;
		margin: 0;
		opacity: .5;
		padding: 0;
		right: 0;
		top: 0;
		width: 32px;
		&:hover {
			opacity: 1;
		}
		span {
			margin: 0;
			padding: 0;
		}
	}
	h1,
	h2 {
		font-size: 1.8em;
		font-family: $Regular;
		margin: 5px 158px 5px 0;
	}
}

.no-close .ui-dialog-titlebar-close {
	display: none;
}

.ui-accordion .ui-accordion-content {
	padding: 0;
}

.ui-tabs {
	.ui-tabs-nav {
		height: 2.45em;
		padding: 0;
		li {
			border-color: $white;
			border-style: solid;
			border-width: 0 0 3px 0;
			&.ui-state-active {
				border-bottom-color: $blue;
				a {
					color: $black;
					font-family: $Medium;
				}
			}
			a {
				color: $light-gray;
			}
		}
	}
	.ui-tabs-panel {
		background-color: $white;
		border: 1px solid $white;
		line-height: 1.5em;
		margin: 0;
		padding: 20px 10px;
		h3 {
			@include breakpoint(medium-up) {
			display: none;
			}
		}
	}
}
