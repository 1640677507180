#product-nav-container {
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 160px;
    div {
        float: right;
        text-align: center;
        width: 77px;
    }
    img {
        max-height: 100%;
        max-width: 80%;
    }
    span:first-child {
        display: block;
        height: 65px;
    }
    .divided span:first-child {
        border-right: 1px solid $white-alt;
        padding-right: 5px;
    }
}

.pdp-main {
    @extend %clearfix;
    margin: 0 1%;
    
    .pdp-main-inner {
        @extend %clearfix;
        position: relative;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    label {
        padding: 0;
        text-align: left;
        text-transform: uppercase;
        width: auto;
    }
    .product-col-1 {
        box-sizing: border-box;

        @media screen and (min-width: 550px) {
            float: left;
            width: 50%;
            padding-right: 20px;
        }

        @include breakpoint(medium-up) {
            width: 60%;
        }
    }
    .product-col-2 {
        @media screen and (min-width: 550px) {
            float: right;
            width: 50%;
        }

        @include breakpoint(medium-up) {
            width: 40%;
        }
        .price-standard {
            border-right: 1px solid $white-alt;
            padding-right: .5rem;
        }
    }
    .product-image-container {
        .promotion {
            display: block;
            text-align: center;
            @media screen and (min-width: 550px) {
                display: none;
            }
        }
    }
    .product-detail {
        @media screen and (min-width: 550px) {
            position: sticky;
            top: 10px;
        }

        .product-name,
        .product-price {
            display: none;

            @media screen and (min-width: 550px) {
                display: block;
            }
        }
        .promotion {
            display: none;
            @media screen and (min-width: 550px) {
                display: block;
            }
        }

        .promo-message-bottom {
            .promotion {
                display: block;

                .promotion-callout {
                    margin-top: 20px;
        
                    .callout-message {
                        display: none;
                    }
                }
            }
        }
    }

    .promo-message-bottom {
        .promotion-callout {
            margin-top: 20px;

            .callout-message {
                display: none;
            }
        }
    }

    .promo-message-top {
        .callout-details {
            display: none;
        }
    }

    .product-name {
        margin-bottom: .3rem;

		&.mobile-only {
			font-size:1.5em;
			font-weight:normal;
			margin-bottom:0.6em;
		}
    }
    .product-number {
        color: $tan;
        font-size: .65rem;
        margin-bottom: .5rem;
    }
    .product-price {
        font-size: 1.5rem;
        margin-bottom: 0;
        margin-top: 15px;

        @include breakpoint(medium-up) {
            margin-bottom: 20px;
            margin-top: 20px;
        }

        .price-standard {
            color: $light-gray;
            font-weight: 100;
            text-decoration: line-through;
        }
        .price-sales {
            &.m-sale-price {
                color: $error-red;
                font-weight: 700;
            }
        }
        .price-tiered {
            color: $error-red;
            font-size: .6em;
            line-height: 1.5em;
        }
    }
    .product-review {
        @extend %clearfix;
        margin-bottom: .5rem;
    }
    .product-main-attributes {
        .attribute {
            padding: .2em 0;
        }
        .label {
            padding: 0;
        }
    }
    .product-primary-image {
        max-width: 100%;
        text-align: center;
    }
    .product-info {
        padding-top: 40px;

        @include breakpoint(medium-up) {
            padding-top: 20px;
        }

        &-wrapper {
            clear: both;
        }
		.long-description {
			font-size: 16px;
			padding: 10px;
			margin: 0 auto;
            ul {
                margin: 10px 0px; 
                padding-right: 0px; 
                padding-left: 0px;
                color: $black;
                font-size: 16px;
                li {
                    margin: 0; 
                    padding: 0px; 
                    list-style: disc outside none;
					margin-left: 2.2em;
					line-height: 1.6em;
                }
            }
		}
    }
    // Product Add to Cart Area (Availability, Quantity)
    .product-add-to-cart {
        border-bottom: 1px solid $tan;
        border-top: 1px solid $tan;
        display: block;
        margin: 20px 0 0;
        padding: 1em .5em;
        overflow: hidden;
        .inventory {
            // float: left;
            white-space: nowrap;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;

            @include breakpoint(large) {
                flex-direction: row;
            }

            label {
                display: inline;
                float: none;
                text-align: right;
            }
            input {
                font-size: 14px;
                height: 50px;
                width: 50px;
                padding:0.8em 0.5em;
                text-align: center;
                border-radius: 0;
                @include breakpoint(medium-up) {
                    height: 37px;
                    width: 50px;
                }
            }
            .quantity {
                display: flex;
                justify-content: center;

                @media screen and (min-width: 550px) {
                    justify-content: flex-start;
                }
            }
            .quantity-button {
                float: none;
                padding: 0 15px;
                line-height: 30px;
                vertical-align: bottom;
                font-size: 34px;
                color: $light-gray;
                border-color: $light-gray;
                width: auto;

                &.quantity-decrease-button {
                    border-radius: 4px 0 0 4px;
                    border-right: none;

                    &:hover {
                        background-color: $blue-medium-dark;
                        color: $white;
                    }
                }
                &.quantity-increase-button {
                    border-left: none;
                    border-radius: 0 4px 4px 0;

                    &:hover {
                        background-color: $blue-medium-dark;
                        color: $white;
                    }
                }
            }

            .size-chart-btn {
                border-color: $blue-medium-dark;
                color: $blue-medium-dark;
                width: auto;
                padding: 0 10px;

                &:hover {
                    background-color: $blue-medium-dark;
                    color: $white;
                }
            }
        }
        button {
            font-family: $Medium;
            height: 50px;
            padding: .2em .7em;
            white-space: nowrap;
            width: 36.5%;
            
            &.add-all-to-cart {
                width: auto;
            }

            &.add-to-cart-btn {
                width: 100%;
                margin: 20px auto 0;
                display: block;
                font-size: 16px;
            }

            @include breakpoint(medium-up) {
                height: 37px;
                padding: .5em 2em;
                width: auto;
            }
            @include breakpoint(medium-down) {
                font-size: 20px;
            }
            @media only screen and (device-width : 375px) { 
                font-size: 18px; 
            }
            @media only screen
                and (min-width : 768px)
                and (max-width : 1024px)
                and (orientation : portrait) {
                    width: 155px;
                    padding: 0;
            }
            @media only screen 
                and (min-device-width : 768px) 
                and (max-device-width : 1024px) 
                and (orientation : portrait)
                and (-webkit-min-device-pixel-ratio: 2) {
                    width: 155px;
                    padding: 0;
            }
            @media only screen 
                and (min-width: 1024px) 
                and (max-height: 1366px) 
                and (orientation: portrait) 
                and (-webkit-min-device-pixel-ratio: 1.5) {
                    width: 172px;
            }
        }
        .dw-apple-pay-button {
            margin-left: .5rem;
            @include breakpoint(medium-up) {
                width: 172px; // make sure apple pay button is as big as Add To Cart
            }
            @media only screen
                and (min-width : 768px)
                and (max-width : 1024px)
                and (orientation : portrait) {
                    width: 155px;
            }
            @media only screen 
                and (min-device-width : 768px) 
                and (max-device-width : 1024px) 
                and (orientation : portrait)
                and (-webkit-min-device-pixel-ratio: 2) {
                    width: 155px;
            }
            @media only screen 
                and (min-width: 1024px) 
                and (max-height: 1366px) 
                and (orientation: portrait) 
                and (-webkit-min-device-pixel-ratio: 1.5) {
                    width: 172px;
            }
        }
    }
    .promotion {
        overflow: hidden;
        padding: 0;

        .promotion-title {
            font-family: $Bold;
            margin-bottom: .3rem;
            text-transform: uppercase;
            display: none;
        }
        .promotion-callout {
            color: $navy-alt;
            font-size: 1rem;

            .tooltip {
                font-size: .7rem;
                margin-left: .2rem;
            }
        }
    }
    .product-actions {
        @extend %clearfix;
        padding: 1em .5em;
        .socialsharing {
            float: none;
            margin-right: 1rem;
            padding-top: 1em;
            @include breakpoint(large) {
                float: left;
                padding-top: 0;
            }
        }
        a {
            margin-right: .1rem;
            &.share-icon {
                color: $white;
				display:inline-block;
				width:40px;
				height:40px;
				border-radius: 20px;
				background-color:$light-gray;
				text-align:center;
				&:hover {
					background-color:$gray;
				}
				&[data-share='facebook'] {
					background-color:$facebook;	
					&:hover {
						background-color: darken($facebook, 10%);
					}
				}
				&[data-share='twitter'] {
					background-color:$twitter;
					&:hover {
						background-color: darken($twitter, 10%);
					}
				}
				&[data-share='googleplus'] {
					background-color:$google-plus;
					&:hover {
						background-color: darken($google-plus, 10%);
					}
				}
				&[data-share='pinterest'] {
					background-color:$pinterest;
					&:hover {
						background-color: darken($pinterest, 10%);
					}
				}
				
                i {
					margin:auto;
					line-height:40px;
                    font-size: 1.5rem;
                }
            }
        }
    }
    // Product Set Display
    .product-set {
        .product-set-list {
            border-top: 1px solid $tan;
            .product-set-details {
                float: left;
                padding-left: .5rem;
                width: 75%;
            }
            .product-set-item {
                clear: both;
                overflow: hidden;
                padding: 1em 0 0;
            }
            .product-set-image {
                float: left;
                width: 22%;
                img {
                    width: 100%;
                }
            }
            .product-name {
                float: none;
                font-size: 1.3em;
                margin-bottom: 0;
                width: auto;
            }
            .product-price {
                font-size: 1rem;
                margin: .5em 0;
            }
            .attribute {
                h3 {
                    width: 25%;
                }
                .label {
                    width: 25%;
                    white-space: nowrap;
                }
            }
        }
        .product-add-to-cart {
            border-top: none;
            padding-top: 0;
			clear:left;
            label {
                float: left;
                font-size: .75rem;
                padding-top: .3em;
                padding-right: .5em;
            }
            .availability-web {
                label {
                    float: none;
                    padding: top;
                }
            }
        }
    }
    // Product Recommendations
    .recommendations {
        clear: left;
        float:left;
        width:100%;
        padding: 4em 0;
        .recommendation-tooltip {
            background: none repeat scroll 0 0 $white;
            border: 1px solid;
            left: 50%;
            position: absolute;
            text-align: left;
            top: 50%;
            width: 300px;
            z-index: 100;
        }
        h2 {
            font-size: 2.5em;
            font-family: $Regular;
            margin-left: 14px;
            text-align: center;
            color: $navy-alt;
        }
        #carousel-recommendations {
            margin: auto;
            position: relative;
        }
        .product-tile {
            padding: 0 2em;
        }
        .product-name {
            font-size: 1em;
            margin: .2em 0;
            width: 100%;
        }
        .product-price {
            font-size: 1em;
        }
        .product-tile:hover .recommendation-tooltip {
            display: block !important;
        }

        
    }

    .slick-prev {
        transform: translate(0, -50%) rotate(-135deg);
        left: 10px;
    }

    .slick-next {
        transform: translate(0, -50%) rotate(45deg);
        right: 10px;
    }

    .slick-prev,
    .slick-next {
        z-index: 5;
        width: 20px;
        height: 20px;
        border-top: 5px solid $blue-medium-dark;
        border-right: 5px solid $blue-medium-dark;
        border-radius: 0;
        opacity: 0.6;

        &::before {
            display: none;
        }
    }
}

.m-pdp-with-sticky-search {
    .product-detail {
        @media screen and (min-width: 550px) {
            top: 80px;
        }
    }
}

.product-primary-image-carousel {
    display: flex;

    @include breakpoint(large) {
        min-height: 410px;
    }

    .product-primary-image {
        order: 2;
        flex: 0 1 80%;
        max-width: 80%;

        @include breakpoint(small) {
            max-width: 100%;
            flex: 0 0 100%;

        }

        .pdp-primary-slider {
            .main-image {
                padding: 0 5px;
            }

            .slick-arrow {
                opacity: 1;
            }
        }
    }
    .product-thumbnails {
        order: 1;
        flex: 1 0 20%;
        max-width: 20%;

        @include breakpoint(small) {
            display:none;
        }
    }
}

.main-image {
    display: block;
    position: relative;

    @include breakpoint(large) {
        cursor:zoom-in;
    }

    #QuickViewDialog & {
        cursor:default;
    }
    .zoom-img {
        position: absolute !important;
        overflow: hidden;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }

    .badge {
        width:70px;
        height:70px;
        position:absolute;
        display:block;

        img {
            width: 100%;
        }

        &.new {
            top:0;
            left: 5px;
        }
        &.sale {
            bottom:0;
            right:0;
        }
    }
}
.primary-image {
    max-width: 100%;
}

.product-thumbnails {
	> div {
		@extend %clearfix;
	}
    &.single-image {
        .slick-dots {
            display:none;
        }
    }
	@include breakpoint(medium-up) {
		// thumbnail
		.thumb {
			border-bottom: 5px solid $white;
			float: left;
			margin-right: .5rem;
			padding-top: 1px;
			&.slick-current {
				border-color: $blue-medium-dark;
                padding: 0;
			}
		}
		// thumbnail image
		img {
			border: 1px solid $tan;
			box-sizing: border-box;
			cursor: pointer;
			max-width: 100%;
			height: auto;
		}
        &.single-image {
            display:none;
        }
	}
    h2 {
        margin: 1rem 0 .3rem;
    }
}

.rating {
    color: $stjude-red;
    .product-info & {
        font-size: 2em;
    }
}
.short-description {
	padding-bottom: 15px;
	font-size: 16px;
}
.product-variations,
.product-options {
    .attribute {
        margin: 5px 0;
        overflow: hidden;
        padding: 0;
        position: relative;

        @include breakpoint(medium-up) {
            margin: 20px 0 0;
        }

        h3,
        .label {
            font-family: $Bold;
            margin-bottom: .3rem;
            text-transform: uppercase;
        }
        .value {
            width: 100%;
            @include breakpoint(medium-up) {
                width: 75%;
            }
        }
        ul {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            @media screen and (min-width: 550px) {
                float: right;
                display: block;
            }
        }
        .selected-value {
            border: 0 none;
            color: $blue;
            font-family: $Bold;
            padding: 1.3em 0 0 .4em;
            text-transform: uppercase;
            @include breakpoint(medium-up) {
                padding-top: .8em;
            }
        }
        .size-chart-link {
            clear: both;
            float: none;
            a {
                border-color: $white !important;
                padding: 0;
            }
        }
    }
    .swatches li {
        @media screen and (min-width: 550px) {
            float: left;
        }

        &:hover,
        &.selected {
            a {
                border-color: $blue-medium-dark;
                background-color: $blue-medium-dark;
                color: $white;
            }
        }
        &.unselectable {
            a {
                color: $white-alt;
                opacity: .8;

                &.unselectable {
                    border-color: #dfe4e9;
                    color: #dfe4e9;
                    position: relative;

                    svg {
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
            }
            &:hover {
                a {
                    border-color: $white-alt;

                    &.unselectable {
                        background-color: transparent;
                        border-color: #dfe4e9;
                        color: #dfe4e9;
                        cursor: not-allowed;
                    }
                }
            }
        }
        a {
            border: 2px solid $light-gray-partial-transparent;
            border-radius: 4px;
            text-decoration: none;
            display: block;
            line-height: 1.7;
            min-height: 2rem;
            min-width: 2rem;
            margin: .25rem;
            padding: 1px 10px;
            font-size: 20px;
            text-align: center;
            color: $light-gray;
            white-space: nowrap;

            @media screen and (min-width: 550px) {
                margin: 0 .5rem .5rem 0;
            }
        }
    }
    .color li {
        a {
            line-height: 0;
            width: 2.625rem;
            height: 2.625rem;
            @include breakpoint(medium-up) {
                width: 2rem;
                height: 2rem;
            }
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    select {
        background-color: $white;
        border: 1px solid $white-alt;
        border-radius: 0;
        height: 42px;
        width: 100%;
        margin-left: 0;
        @include breakpoint(medium-up) {
            height: 32px;
        }
    }
}

// Tabs using just CSS, no javascript required
.tabs {
    position: relative;
    text-align: center;
}
.tab {
    @include breakpoint(medium-up) {
        display: inline-block;
        float: none;
    }
    .tab-switch {
        display: none;
        &:checked {
            + .tab-label {
                @include breakpoint(medium-up) {
                    background: #fff;
                    border-bottom-color: $blue;
                    color: $black;
                    font-family: $Medium;
                    top: -0.0625rem;
                    transition: all 0.35s;
                    z-index: 1
                }
            }
            + label + .tab-content {
                opacity: 1;
                transition: all 0.35s;
                z-index: 2;
            }
        }
    }
    .tab-label {
        border-bottom: 3px solid $white;
        color: $light-gray;
        display: block;
        font-family: $Regular;
        line-height: 2.5em;
        margin-bottom: 0;
        position: relative;
        text-transform: none;
        top: 0;
        transition: all 0.25s;
        @include breakpoint(medium-up) {
            cursor: pointer;
            padding: 0 1.25rem;
            &:hover {
                border-bottom-color: $light-gray;
                top: -0.25rem;
                transition: top 0.25s;
            }
        }
    }
    .tab-content {
        padding: 1em 0;
        text-align: left;
        z-index: 1;
        @include breakpoint(medium-up) {
            left: 0;
            opacity: 0;
            position: absolute;
            top: 2.75em;
            transition: all 0.35s;
            width: 100%;
        }
    }
}

.pt_product-details {
    .breadcrumb {
        display: none;

        @media screen and (min-width: 550px) {
            display: block;
        }
    }

    #carousel-recommendations .tiles-container,
    .product-listing .search-result-items {
        &:not(.slick-initialized){
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .grid-tile {
                float: none;
                flex: 0 0 25%;
                margin: 0;

                img {
                    max-height: 285px;
                }
            }
        }

        &.slick-initialized {
            display: block;

            .grid-tile {
                padding: 0;
                margin: 0;
                width: 100%;

                .product-image {
                    img {
                        max-height: 285px;
                    }
                }

                & > .product-tile {
                    padding: 0 10px
                }
            }
        }
    }
}

.einstein-recommendation {
    @extend %clearfix;
}

.applepayPDPBtn {
	float:left;
}


.product-details-accordion {
    border-radius: 5px;
    background: $light-gray;
    margin-bottom: 10px;

    &.m-opened {
        .accordion-switcher {
            &::before {
                opacity: 0;
            }
            &::after {
                opacity: 1;
            }
        }
    }
    .accordion-switcher {
        color: $white;
        padding: 5px 10px 5px 30px;
        font-family: $Bold;
        font-size: 18px;
        position: relative;

        &::before,
        &:after {
            position: absolute;
            left: 12px;
            top: 50%;
            transform: translate(0, -50%);
            font-size: 24px;
            transition: 0.125s ease;
        }
        &::before {
            content: '+';
            opacity: 1;
        }
        &::after {
            content: '-';
            opacity: 0;
            transform: translate(0, -55%);
        }
    }
    .accordion-body {
        border: 2px solid $light-gray;
        border-radius: 0 0 5px 5px;
        background: $white;
        padding: 0 10px;
    }
}

.pdp-primary-slider,
.pdp-thumbnails-slider {
    &:not(.slick-initialized) {
        .product-image + .product-image,
        .thumb + .thumb {
            display: none;
        }
    }
}

.recommendations,
.einstein-recommendation {
    .slick-prev {
        transform: translate(0, -50%) rotate(-135deg);
        left: 0px;
    }

    .slick-next {
        transform: translate(0, -50%) rotate(45deg);
        right: 0px;
    }

    .slick-prev,
    .slick-next {
        z-index: 5;
        width: 20px;
        height: 20px;
        border-top: 5px solid $blue-medium-dark;
        border-right: 5px solid $blue-medium-dark; 
        border-radius: 0;
        opacity: 0.6;

        &::before {
            display: none;
        }
    }
}

.product-listing-carousel {
    h2 {
        font-size: 2.5em;
        font-family: $Regular;
        margin-left: 14px;
        color: $black;
        @media screen and (medium-down) {
            text-align: center;    
        }
        
    }
}

.mobile-product-name {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    @media screen and (min-width: 550px) {
		display:none;
	}

    .product-name {
        flex: 0 1 auto;
        font-size: 1.5em;
        margin: 20px 0 10px 0;
    }

    .product-price {
        flex: 1 0 auto;
    }
}

.size-chart-modal {
    .dialog-content.ui-dialog-content {
        overflow: auto;
    }
}

.sku {
    @media screen and (min-width: 600px) {
        text-align: left;
    }
    @media screen and (max-width: 600px) {
        display: none;;
    }
}

.sku-mobile {
    @media screen and (max-width: 600px) {
        text-align: center;
    }
}


.pdp-main {
    .product-sizechart-wrapper {
        .size-chart-btn {
            font-size: 1.1em;
            font-weight: bold;
            height: 37px;
        }
        &.m-desk {
            display: none;
            margin-top: 10px;
            @media screen and (min-width: 550px) {
                display: block;
                height: 37px;
            }
            @include breakpoint(large) {
                margin-top: 0;
                margin-left: auto;
            }
        }
        &.m-mob {
            display: block;
            text-align: center;
            margin-top: 20px;
            @media screen and (min-width: 550px) {
                display: none;
            }
        }
    }
}
