.pt_product-search-result .primary-content h1.content-header {
    padding-left: 15px;
}

.pt_content-search-result,
.pt_product-search-noresult {
    .primary-content {
        > h1, 
        > h2,
        > p {
            margin: 0;
            padding: .1em 1%;
        }
        .no-hits-help,
        .no-hits-search,
        .no-hits-footer {
            padding: .1em 1%;
        }
        .pagination {
            float: none;
            padding: .1em 1%;
        }
    }
}

.pt_product-search-result {
    &#wrapper {
        #main {
            max-width: 100%!important; 
        }
    }
    .primary-content {
        width: 85%;
        @include breakpoint(medium-down) {
            width: 100%;
        }
        &.full-width {
	        width: 100%!important;
	    }
        .container {
            max-width: 100%!important;
        }
    }
    #secondary {
        width: 15%!important;
        background-color: white;
        overflow: auto;
        margin-top: -2rem;
        @include breakpoint(medium-down) {
            position: fixed !important;
            height: 100%;
            /* position: fixed !important; */
            top: 0;
            /* left: 0; */
            display: none!important;
            width: 100% !important;
            z-index: 50;
            margin-top: 0px;
        }
        &.hideContent {
            @include breakpoint(medium-down) {
                display: block!important;
            }
        }
        .apply-filters-container{
            text-align: center;
            display: none;
            padding: 1rem 0 1rem 0;
            button {
                background-color: $blue-medium-dark;
                border: 1px solid $blue-medium-dark;
                font-size: 1.2rem;
            }
            @include breakpoint(medium-down) {
                display: block!important;
            }
        }
    }
    .search-filters {
        padding: 0px 5px;
        overflow: hidden;
        width: 15%;
        .filtersbtn {
            padding: 5px;
            font-size: large;
            border: 0px solid $white;
            color: $black;
            background-color: $white;
            border-right: 1px solid black;
            height: 3rem;
            border-radius: 0px;
            width: 100%;
            @include breakpoint(medium-down) {
                font-size: 1rem; 
                padding: 1rem 1.5rem;
            }
        }
        @include breakpoint(medium-down) {
            display: none;
         }
    }
    .search-result-options {
        width: 20%;
        display: block;
        font-family: $Medium;
        font-size: medium;
        padding: 0px 5px;
        &.mobile {
            width: 100%;
            display: none;
            .filtersbtn {
                border-radius: 30px;
                border: 2px solid $black;
                font-family: $Medium;
                color: $black;
                background-color: $white;
                @include breakpoint(medium-down) {
                    font-size: 1rem; 
                    padding: 1rem 1.5rem;
                }
            }
            span.sortbylable {
                @include breakpoint(medium-down) {
                    width: auto;
                    padding-right: 10px;
                    margin: 15px 0;
                }
            }
            .sort-by {
                @include breakpoint(medium-down) {
                    max-width: 33%;
                    margin: 10px 5px;
                }
            }
            @include breakpoint(medium-down) {
                display: block;
             }
        }
        span.sortbylable {
            width: 30%;
            margin-top: 6px;
            border-right: 1px solid $black-alt;
            font-family: $Bold;
            /*padding-right: 5px;*/
            float: right;
            @include breakpoint(medium-down) {
                padding-right: 10px;
                margin: 15px 0;
            }
        }
        .sort-by {
            float: right;
            width: 60%;
            margin: 0 5px;
            /*padding-bottom: 1em;*/
        }
        .items-per-page {
            float: left;
            margin-right: .5em;
        }
        form {
            margin-bottom: 0;
        }
        label {
            display: none;
        }
        select {
            border: 0px solid $white-alt;
            border-radius: 5px;
            color: $light-gray;
            padding: 0px;
            font-style: normal!important;
            font-family: $Light;
        }
        
        @include breakpoint(medium-down) {
           display: none;
        }
    }
    
    .c-overlay {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        background-color: rgba($gray, 0.68); /*dim the background*/
    }
}

.category-main-banner img {
    max-width: 100%;
}

.no-hits-search-term,
.no-hits-search-term-suggest {
    color: $error-red;
}

.no-hits-footer {
    background: url("../images/interface/icon-double-line-header.png") repeat-x scroll left top transparent;
    margin: 20px 0;
    padding: 20px 0;
}

.search-result-bookmarks {
    margin: 15px;
    .first {
        border-right: 1px solid $light-gray;
        padding-right: 5px;
    }
}

.search-result-options {
    padding: 1em 0.5em;
    overflow: hidden;
    .items-per-page {
        float: left;
        margin-right: .5em;
    }
    form {
        margin-bottom: 0;
    }
    label {
        display: none;
    }
    select {
        border: 1px solid $white-alt;
        border-radius: 5px;
        color: $light-gray;
        padding: .5em;
    }
    .pagination {
        float: right;   
        list-style:none;
        @include breakpoint(medium-up) {
            padding-top: .8em;
        }
        .results-hits {
            color: $light-gray;
            float: left;
            display: none;
            @include breakpoint(medium-up) {
                display: block;
            }
        }
        ul {
            border: 1px solid $white-alt;
            border-radius: 5px;
            background-color: $white;
            float: right;
            margin: 0;
            padding: 0;    
            list-style: none;
            @include breakpoint(medium-up) {
                background-color:transparent;
                border: none;
            }
        }
        li {            
            border-right: 1px solid $white-alt;
            float: left;
            @include breakpoint(medium-up) {
                border: none;
                padding: 0 .5em;
            }
            a,
            &.current-page,
            i {             
                display: block;
                font-size: 1rem;
                height: 32px;
                line-height: 2.1rem;
                text-align: center;
                width: 32px;
                @include breakpoint(medium-up) {
                    font-size: inherit;
                    height: auto;
                    line-height: inherit;
                    width: auto;
                }
            }
        }
    }
}

.search-result-items {
    margin: 0;
    padding: 0;
    li {
        list-style: none outside none;
    }
    .new-row {
        clear: both;
    }
    .grid-tile {
        background: none repeat scroll 0 0 $white;
        float: left;
        list-style: none outside none;
        margin: 0 1% 2% 1%;
        padding: 1%;
        width: 26%;
        @include breakpoint(medium-up) {
            
            &.col-2 {
                width: 46%!important;
            }
            &.col-3 {
                width: 26%!important;
            }
            &.col-4 {
                width: 23%!important;
                margin: 0 1% 2% 1%!important;
                padding: 0!important;
            }
            &.col-5 {
                width: 16%!important;
            }
            &.col-6 {
                width: 12%!important;
            }
        }
    }
    .invisible {
        display: none;
    }
}

.einstein-recommendation {
    .search-result-items {
        .grid-tile {
            padding: 20px;
            @include breakpoint(medium-down) {
                padding: 10px;
                margin: 0 0.1rem;
            }
        }
    }
}

.search-result-content {
    clear: both;
    .product-price {
        .price-standard {
            color: #999;
            font-weight: 100;
            text-decoration: line-through;
        }
    }
}
.product-tile{
    .product-variations,
    .product-options {
        .attribute {
            margin: 5px 0;
            overflow: hidden;
            padding: 0;
            position: relative;

            @include breakpoint(medium-up) {
                margin: 5px 0 0;
            }

            h3,
            .label {
                font-family: $Bold;
                margin-bottom: .3rem;
                text-transform: uppercase;
            }
            .value {
                width: 100%;
                /*@include breakpoint(medium-up) {
                    width: 75%;
                }*/
            }
            ul {
                width: 100%;
                display: grid;
                grid-template-columns: auto auto auto;
                justify-content: center;
                flex-wrap: wrap;
                float: right;

                @media screen and (max-width: 550px) {
                   padding: 0px;
                }
            }
            .selected-value {
                border: 0 none;
                color: $blue;
                font-family: $Bold;
                padding: 1.3em 0 0 .4em;
                text-transform: uppercase;
                @include breakpoint(medium-up) {
                    padding-top: .8em;
                }
            }
            .size-chart-link {
                clear: both;
                float: none;
                a {
                    border-color: $white !important;
                    padding: 0;
                }
            }
        }
        .swatches li {
            width: 32%;

            &:hover,
            &.selected {
                a {
                    border-color: $blue-medium-dark;
                    background-color: $blue-medium-dark;
                    color: $white;
                }
            }
            &.unselectable {
                a {
                    color: $white-alt;
                    opacity: .8;

                    &.unselectable {
                        border-color: #dfe4e9;
                        color: #dfe4e9;
                        position: relative;

                        svg {
                            position: absolute;
                            left: 0;
                            top: 0;
                        }
                    }
                }
                &:hover {
                    a {
                        border-color: $white-alt;

                        &.unselectable {
                            background-color: transparent;
                            border-color: #dfe4e9;
                            color: #dfe4e9;
                            cursor: not-allowed;
                        }
                    }
                }
            }
            a {
                border: 2px solid $light-gray-partial-transparent;
                border-radius: 4px;
                text-decoration: none;
                display: block;
                line-height: 1.4;
                min-height: 1rem;
                /*min-width: 1rem;*/
                /*margin: .25rem;*/
                padding: 1px;
                font-size: 1em;
                text-align: center;
                color: $blue;
                white-space: nowrap;

                @media screen and (min-width: 550px) {
                    margin: 2px;
                }
            }
        }
        .color li {
            a {
                line-height: 0;
                width: 2.625rem;
                height: 2.625rem;
                @include breakpoint(medium-up) {
                    width: 2rem;
                    height: 2rem;
                }
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        select {
            background-color: $white;
            border: 1px solid $white-alt;
            border-radius: 0;
            height: 42px;
            width: 100%;
            margin-left: 0;
            @include breakpoint(medium-up) {
                height: 32px;
            }
        }
        ul {
           // @media screen and (max-width: 550px) {
               padding: 0px;
            //}
        }
    }
    .variation-container{
        /*min-height: 70px;*/
        padding: 5px 0;
    }
}

.search-promo {
    padding: 20px;
}

#results-content {
    background: url("../images/interface/icon-double-line-header.png") repeat-x scroll left top transparent;
    padding-top: 30px;
}

.folder-content-list {
    align-items: stretch;
    clear: both;
    display: flex;
    flex-wrap: wrap;
    padding: .5em 1%;
    li {
        background-color: $white-alt;
        border: 1px solid $tan;
        flex-basis: 100%;
        flex-grow: 1;
        list-style-type: none;
        margin: .25rem;
        padding: 1rem;
        @media screen and (min-width: 480px) {
            flex-basis: 40%;
        }
    }
    p {
        line-height: 1.1rem;
    }
    .content-title {
        display: block;
        font-size: 1.2em;
    }
    .readmore {
        padding-left: .3rem;
    }
}

.no-results {
    padding: 20px;
}

.toggle-grid {
    display: none;
    float: left;
    margin-right: 0.5em;
    i {
        cursor: pointer;
        margin-right: 0.3em;
    }
    [data-option="column"] {
        color: $blue;
    }
    &.wide {
        [data-option="wide"] {
            color: $blue;
        }
        [data-option="column"] {
            color: $light-gray;
        }
    }
}

.wide-tiles {
    .search-result-items .grid-tile {
        width: 91%;
    }
    .product-tile {
        .product-swatches {
            bottom: auto;
            left: auto;
            position: relative;
            right: auto;
            margin-top: 5px;
            li {
                float: left;
            }
        }
    }
}

.pt_product-search-noresult {
    .product-listing.nohit {
        .search-result-items.slick {
            .grid-tile {
                padding-bottom: 0px;
                margin-bottom: 0px;
            }
        }
    }
}

.slot-grid-header {
    padding: 0px 5px;
    min-height: 3rem;
    h1 {
        font-size: 2.25em;
        font-family: $Medium;
        margin: 0px;
        @include breakpoint(medium-down) {
            font-size: 1.3rem;
        }
    }
}

.top-content {
    min-height: 6rem;
    .slot-grid-header{
        position: relative;
        width: 65%;
        @include breakpoint(medium-down) {
            padding: 0 10px;   
            width: 100%!important;
        }
    }
    .category-banner {
        display: ruby;
    }
    .breadcrumb {
        /*position: absolute;*/
        /*bottom: -2.5em;*/
        padding: 1.3em 0px;
        clear: both;
        a {
            color:$blue-alt;
            font-size: 1.2em;
        }
    }
    .breadcrumb-element {
        &::after {
            color:$blue-alt;
            content: '/';
        }
        &:last-child {
            &::after {
                content: ''!important;
            }
        }
    }
}

.totalproductnumber {
    font-family: $Bold;
 }

 .loadmore-container{
    display: flex;
    width: 100%;
    .buttonloadmore {
        padding: 1em 2.5em;
        margin: auto;
        background-color: $blue-medium-dark!important;
    }
}