#wrapper {
    background-color: $white;
    border: 0;
    left: 0;
    margin: auto;
    position: relative;
    transition: left 0.2s ease-in;
    width: 100%;

    #main {
        margin: auto;
        max-width: $content-max-width;
        box-sizing: border-box;
        position: relative;
        /*@include breakpoint(medium-up) {
            width: 752px;
        }
        @include breakpoint(large) {
            width: 980px;
        }*/
    }
    &.pt_storefront {
        width: 100%;

        #main {
            width: unset;
            max-width: none;
        }
    }
    &.pt_categorylanding {
        width: 100%;
        .primary-content {
            padding-bottom: 0;
            @include breakpoint(medium-up) {
                width: 80%;
            }
        }
        .refinements {
            border-right: 0;
            display: none;
            width: 0;
            @include breakpoint(medium-up) {
                display: block;
                width: 20%;
            }
        }
    }
    &.pt_product-details {
        .primary-content {
            padding-top: 1rem;
        }
    }
    &.menu-active {
        left: 50%;
    }
    @include breakpoint(medium-up) {
        &.menu-active {
            left: auto;
        }
    }
}

.top-content {
    margin: auto;
    display: flex;
    align-items: flex-start;
    margin-top: 1rem;
    max-width: $content-max-width;
    @include breakpoint(medium-up) {
        max-width: $medium-max;
    }
    @include breakpoint(large) {
        max-width: $large-max;
    }
}

.primary-content {
    width: 100%;
    padding: 0 10px 20px;
    box-sizing:border-box;
    @include breakpoint(medium-up) {
        /*border-left: 1px solid $white-alt;*/
        float: right;
        margin-left: -1px;
        position: relative;
        width: 787px;
	    .primary-focus & {
	        border: none;
	        float: left;
	        margin: 0;
	        width: 76%;
	    } 
	    .full-width & {
	        border: none;
	        width: 100%;
	    }
	 }
}

#secondary {
    border: 0;
    float: none;
    font-size: 1.1em;
    width: 100%;
    padding:10px;
    box-sizing:border-box;
    @include breakpoint(medium-up) {
        /*background: url("../images/interface/icon-double-line-header.png") repeat-x scroll left top transparent;*/
        background: none;
        /*border-right: 1px solid $white-alt;*/
        float: left;
        width: 172px;
        position: relative;
        padding:0;
        nav {
            padding-left: 10px;
            padding-right: 10px;
            a {
                display: block;
            }
        }
        .primary-focus & {
            background: none;
            border: none;
            box-sizing: border-box;
            float: left;
            padding-left: .4rem;
            width: 24%;
        }
    }
    // Remove Refinements for responsive design
    .pt_content-search-result &,
    .pt_order & {
        display: none;
        @include breakpoint(medium-up) {
            display: block;
        }
    }
}

.showContent {
    display: block!important;
}

.hideContent {
    display: none!important;
    #closethick {
        display: none;
        position: absolute;
        background-image: url("../images/interface/icon-ui-close.png");
        background-position: left top;
        height: 32px;
        width: 32px;
        right: 0;
        top: 0;
        margin-left: 0;
        margin-top: 0;
        @include breakpoint(medium-down) {
            display: block;
        }
    }
}


.secondary-content {
    clear: both;
}

h2.sitemap-title a {
	color: $normalized-black;
	text-transform: uppercase;
	font-size: 1em;
	&:hover {
		text-decoration: none;
	}
}