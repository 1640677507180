// mixins
%clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

.clearfix {
	@extend %clearfix;
}

// fonts;
$stjude-base: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

//font-url
$font-url: '../fonts/stjude-sans/StJudeSansWeb';

@each $family in Bold,Book,Light,Medium,Regular {
  @font-face {
    font-family: 'StJude #{$family}';
    src: url('#{$font-url}-#{$family}.eot');
    src: url('#{$font-url}-#{$family}.eot?#iefix') format('embedded-opentype'),
         url('#{$font-url}-#{$family}.woff') format('woff'),
         url('#{$font-url}-#{$family}.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'StJude #{$family}Italic';
    src: url('#{$font-url}-#{$family}Italic.eot');
    src: url('#{$font-url}-#{$family}Italic.eot?#iefix') format('embedded-opentype'),
         url('#{$font-url}-#{$family}Italic.woff') format('woff'),
         url('#{$font-url}-#{$family}Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
}

$Light: "StJude Light", $stjude-base;
$LightItalic: "StJude LightItalic", $stjude-base;

$Regular: "StJude Regular", $stjude-base;
$RegularItalic: "StJude RegularItalic", $stjude-base;

$Book: "StJude Book", $stjude-base;
$BookItalic: "StJude BookItalic", $stjude-base;

$Medium: "StJude Medium", $stjude-base;
$MediumItalic: "StJude MediumItalic", $stjude-base;

$Bold: "StJude Bold", $stjude-base;
$BoldItalic: "StJude BoldItalic", $stjude-base;

// colors
$normalized-black: #1a1a1a;
$black: #1a1a1a;
$black-alt: #444445;

$navy: #0D5679;
$navy-alt: #0E6C99;

$stjude-red: #c61b3b;
$stjude-red-alt: #c61b3b;

$tan: #B0AAA3;

$error-red: #c10f3a;
$error-red-alt: #CD2F3F;

$call-to-action-red: #D11947;
$call-to-action-red-alt: #D53253;

$gray: #59595C;

$light-gray: #808080;
$light-gray-partial-transparent: rgba($light-gray, 0.4);
$light-gray-mostly-transparent: rgba($light-gray, 0.2);

$white: #FFFFFF;
$white-alt: #F9FAFB;

$blue: #0080A7;
$blue-alt: #269FD9;
$blue-medium-dark: #135cb0;

$green: #28A873;
$green-alt: #58BB72;

$ui-overlay: rgba(0, 0, 0, 0.6);
$navigation-gray: #EFF2F3;
$background-white: #f6f6f6;

$facebook: #4A67AD;
$twitter: #5393D3;
$pinterest: #AD2626;
$youtube: #EA3323;
$google-plus: #C94C38;
$instagram: #59595C;
$swatch-types: black #000000, blue #0000ff, purple #9a32cd, red #bf0d3e, green #61a644, grey #cbc7c7, orange #ffa500, white #ffffff, yellow #fcdf50, teal #40e0d0, offwhite #ffefdb, gold #daa520, brown #835011;

// flag-icon-css
$flag-icon-css-path: '../lib/flag-icon-css/flags';

// global classes
.visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.menu {
	float: left;
	margin: 0;
	padding: 0;
	li {
		float: left;
		list-style: none outside none !important;
	}
}

$content-max-width: 980px;

$small-max: 767px;
$medium-min: $small-max + 1;
$medium-max: 959px;
$large-min: $medium-max + 65;
$large-max: $medium-max + 241;

@mixin breakpoint($size) {
    @if $size == "small" {
        @media screen and (max-width: $small-max) {
            @content;
        }
	}
	@else if $size == "medium" {
        @media screen and (min-width: $medium-min) and (max-width: $medium-max) {
            @content;
        }
	}
	@else if $size =="medium-up" {
        @media screen and (min-width: $medium-min) {
            @content;
        }
	}
	@else if $size == "medium-down" {
        @media screen and (max-width: $medium-max) {
            @content;
        }
    }
	@else if $size == "large" {
        @media screen and (min-width: $large-min) {
            @content;
        }
	}
}

.mobile-only {
	@include breakpoint(medium-up) {
		display:none;
	}
}
.hide-mobile {
	@include breakpoint(small) {
		display:none;
	}
}
