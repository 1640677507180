.product-tile {
	@extend %clearfix;
	color: $light-gray;
	position: relative;
	text-align: center;
	img {
		max-width: 100%;
	}
	.product-image {
		height: auto;
        width:auto;
        display:inline-block;
		position: relative;
		.quickview {
			color: #fff;
			display: none;
			width: 55%;//For IE
			width: max-content;
			max-width: 75%;
			top: 50%;
			left: 50%;
			position: absolute;
			transform: translate(-50%, -50%);
			i {
				margin-right: 0.5em;
			}
		}
		&:hover {
			.quickview {
				display: block;
			}
		}
        .badge {
            width:70px;
            height:70px;
            position:absolute;
            display:block;
            &.new {
                top:0;
                left:0;
            }
            &.sale {
                bottom:0;
                right:0;
            }
        }
		.hoverImg {
			/*position: absolute;
			left: 0;
			top: 0;*/
			display: none;
		}
		
		&:hover .imageInn {
			display: none;
		}
		
		&:hover .hoverImg {
			display: block;
		}
	}
	.product-name {
		font-size: 1.2em;
		margin-top: 1em;
		min-height: 4em;
		overflow: auto;
		text-wrap: balance;
	}
	.product-pricing {
		margin: 0.5em 0;
		font-family: $Bold;
		font-size:1.1em;
		color: $black;
		.product-standard-price {
			text-decoration: line-through;
			+ .product-sales-price {
				color: $error-red;
			}
		}
	}
	.grid-tile & .product-swatches {
		float: right;
	}
	.product-swatches-all {
		cursor: pointer;
		font-size: 0.9em;
	}
	.product-swatches {
		ul {
			list-style: none outside none;
			margin: 0;
			overflow: hidden;
			padding: 0;
			&.swatch-toggle {
				background: none repeat scroll 0 0 $white;
				display: none;
				position: absolute;
			}
		}
		display: none;

		.pt_product-search-result &, .pt_product-compare & {
   			display: block;
   		}

		&:hover ul.swatch-toggle {
			display: block;
		}
		li {
			float: left;
		}
		.swatch {
			display: block;
			width: 16px;
			img {
				border: 1px solid $white;
				height: 12px;
				padding: 1px;
				width: 12px;
			}
		}
		.selected img, img:hover {
			border-color: $blue;
		}
	}
	.product-promo {
		font-size: 0.8em;
		margin: 0.5em 0;
		text-transform: uppercase;
	}
	.product-compare {
		float: left;
		line-height: 11px;
		label {
			font-family: $Regular;
			line-height: 1;
			padding: 0 0 0 .5em;
			width: auto;
		}
		input {
			float: left;
			margin: 0;
		}
	}
	.product-review {
		margin-bottom: 1em;
		.rating-title {
			display: inline-block;
			margin-right: 1em;
		}
		.rating {
			display: inline-block;
		}
	}
	.wide-tiles & {
		height: auto !important; // overwrite synced heights set on element
		.product-image {
			float: left;
			padding-right: 2em;
		}
		.product-name,
		.product-pricing,
		.product-promo,
		.product-swatches,
		.product-review {
			float: left;
			text-align: left;
			width: 65%;
		}
		.product-name {
			font-size: 1.5em;
			height: auto;
			margin-top: 0;
		}
		.product-pricing {
			font-size: 1.3em;
		}
		.product-swatches {
			margin-bottom: 1em;
		}
	}
}

.capture-product-id {
	left: -999em;
	position: absolute;
}
