@import "nav";
@import "menu_utility";
@import "country_selector";

.top-banner {
	position: relative;
	* {
		box-sizing: border-box;
	}
	@include breakpoint(small) {
		background-color: $stjude-red;
		.sj-child-logo {
			display: block!important;
		}
	 }
	.sj-child-logo {
		display: none;
		width: 4rem;
		height: 4rem;
	}
}

.primary-logo {
	height: 75px; // height of image
	padding: .5em 0 0;
	margin: 0 auto;
	width: 210px; // width of image
	z-index: 1;
	@include breakpoint(medium-up) {
	   height: 48px; // height of image
		padding: 0.5em 0 0 .5em;
		margin: .5em 0 0 .5em;
	}
    a {
        display:block;  
        img {
            max-width:100%;
            max-height:100%;
        }
    }
	@include breakpoint(small) {
		.img-logo-desktop {
			display:none;
		}
	}
}

.header-banner {
	display:block;
	.header-promotion {
		background-color: $stjude-red;
		color: #fff;
		padding: 3px 0 2px;
		text-align: center;
	}
}

.header-search {
    background-color: $white;
    color: $light-gray;
    /*height: 3.3em;*/
    margin: 5px auto 10px;
    position: relative;
    z-index: 1;
	display: block;
	padding: 5px;
	@include breakpoint(medium-up) {
        margin:-30px auto 10px;
    	width: 300px;
	}
	&.m-sticky-enable {
		z-index: 10;
		form {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			animation: showStickySearchBar 0.25s ease-in-out;

			@include breakpoint(medium-up) {
				position: static;
			}

			fieldset {
				margin: 20px auto;
			}
		}
	}
	form {
		margin-bottom: 1px;
		background: $white;
		position: relative;
		top: 0;
		transition: 0.25s ease;

		fieldset {
			border: 1px solid $light-gray;
			border-radius: 3px;
			padding:0 1em;
			width: 280px;
			margin: 0 auto;
			@include breakpoint(medium-down) {
				margin: 5px auto;
				width: 100%;
			}
		}
	}
	input[type="text"] {
		background-color: transparent;
		border: none;
		float: right;
		font-family: $RegularItalic;
		margin: 0;
		padding-left: 1rem;
		height: 3.5rem;
		outline:none;
		width: 85%; // default for older browser
		width: calc(100% - 2rem);
		font-size: 1rem;
		@include breakpoint(medium-up) {
			height: 2.8rem;
			padding: 0;
		}
	}
	button {
		float:left;
		background-color: transparent;
		border: none;
		color: $light-gray;
		padding: 1em 0;
		font-size: medium;
		@include breakpoint(medium-up) {
			padding: 0.7em 0;
		}
		i {
			font-size: 1.4em;
		}
	}
	&:hover {
		button {
			color: $blue;
		}
	}
	
}

.header-search-icon {
	position: absolute;
    right: 3.5rem;
    top: 10px;
    z-index: 20;
    color: $white;
    font-size: 30px;
	@include breakpoint(medium-up) {
		display: none;
	}
}

@keyframes showStickySearchBar {
	from {
	  transform: translateY(-100%);
	}
  
	to {
	  transform: translateY(0%);
	}
  }
