.pt_gift-registry {
	.create-new-registry {
		margin: 1em 0;
		button {
			float: left;
			margin-right: 2em;
		}
		p {
			font-size: 1.3em;
		}
	}
	.add-gift-cert {
		border: 1px solid $white-alt;
		margin-bottom: -1px;
		overflow: auto;
		padding: .83em;
		p {
			color: $light-gray;
			line-height: 1.5em;
		}
	}
	.hdr-options {
		width: 9%;
	}
}

.page-content-tab-navigaton button {
	background-color: $white-alt;
	border-color: $white-alt;
	border-width: 1px;
	color: $light-gray;
	font-size: 1.1em;
	font-family: $Regular;
	margin: 0 0 -1px;
	&.selected {
		background-color: $white;
		border-bottom: 1px solid $white;
	}
}

.page-content-tab-wrapper {
	border: 1px solid $white-alt;
	padding: .42em;
}

.gift-reg-purchases {
	.item-purchased-by {
		dd {
			float: left;
			font-family: $Bold;
			margin: 0 1em 0 0;
			position: relative;
			width: auto;
		}
		dt {
			clear: both;
			float: left;
			margin: 0 1em 0 0;
			position: relative;
			width: auto;
		}
	}
	.quantity-purchased {
		color: $error-red;
	}
}

.gift-registry-advanced {
	@extend %clearfix;
	.toggle-content {
		display: none;
	}
}
