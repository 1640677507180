.live-agent-wrapper{

	position:fixed;
	bottom:0;
	right:15px;
	z-index:9999;
	
	a.chat-bug {
		transform:translate3d(0,0,0);
		background-color:#1874dc;
		padding:10px 15px 10px 50px;
		position:relative;
		border-radius:3px 3px 0 0;
		color:#fff;
		font-weight:700;
		font-size:14px;
		display:block;
			
		&:before{
			content:"";
			position:absolute;
			left:15px;
			top:9px;
			width:25px;
			height:25px;
			margin-right:10px;
			background:url(../images/icon-chat.svg)
		}
		&:hover{
			padding-bottom:15px;
			cursor:pointer;
		}
		&:active{
			position:unset;
		}
	}
 	
 	&.authorMode{
 		position:relative;
 		bottom:unset;
 		right:unset;
 	}
 	&.authorMode .chat-bug{
 		display:inline-block !important;
 	}
	
}
 
.no-svg .live-agent-wrapper .chat-bug a:before{background-image:url(../images/icon-chat.png)}
