#PasswordResetForm {
    fieldset {
        position: relative;
    }
    button {
        float: right;
        top: 0px;
        left: 63%;
    }
}

h1 .account-logout {
    font-size: 0.6em;
    float: none;
}

.registration-selection {
    input, label {
        display: inline;
        float: none;
    }
}

.address-list,
.payment-list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    li {
        list-style-type: none;
        padding: 1em;
        width: 90%;
        @include breakpoint(medium-up) {
            width: 25%;
        }
        &.default {
            background-color: $white-alt;
            border: 1px solid $tan;
            border-radius: 5px;
            margin-top: 1em;
            width: 85%;
        }
        address {
            color: $light-gray;
            font-family: $Regular;
        }
        a,
        .button-text {
            line-height: 2rem;
            padding-right: .2rem;
        }
    }
    .mini-address-title {
        line-height: 2rem;
    }
    .cc-owner {
        font-family: $Medium;
    }
}

/*** This will hide the Email Opt-In temporarily ***/
.pt_account {
	#RegistrationForm {
		.email-optin {
			display:none;
		}
	}

	.login-box {
		.login-oauth {
			display:none;
		}
	}

  //this will fix the server side error messages that looks different from the client side or JS error messages
  form {
    .required.error {
      .error-message {
        background: transparent;
        border: none;
        padding-top: 0px;
        padding-left: 0px;
      }
    }
  }
}
