.dw-apple-pay-button,
.dw-apple-pay-button:hover {
	margin: 0;
}

#cart-table {
	thead {
		display: none;
		@include breakpoint(medium-up) {
			display: table-header-group;
		}
	}
	td {
		box-sizing: border-box;
		display: block;
		padding: 5px;
		@include breakpoint(medium-up) {
			display: table-cell;
		}
	}
	.cart-row {
		display: flex;
		flex-wrap: wrap;
		@include breakpoint(medium-up) {
			display: table-row;
		}
	}
	.header-total-price {
		text-align: right;
	}
	.item-image {
		float: left; // LEGACY: to support IE (no flexbox)
		order: 1;
		text-align: center;
		flex-basis: 40%;
		@include breakpoint(medium-up) {
			float: none;
			width: 7rem;
		}
	}
	.item-details {
		float: left; // LEGACY: to support IE (no flexbox)
		order: 2;
		flex-basis: 60%;
		@include breakpoint(medium-up) {
			float: none;
		}
		.name {
			font-size: 1rem;
		}
		// Removes Edit Details Quickview link for handheld devices
		.item-edit-details {
			display: none;
			@include breakpoint(medium-up) {
				display: block;
			}
		}
	}
	.item-delivery-options {
		order: 6;
		text-align: center;
		flex-basis: 50%;
		@include breakpoint(medium-up) {
			text-align: left;
			width: 8rem;
		}
		.form-row {
			margin-top: 0;
		}
	}
	.item-quantity {
	    display: flex;
        flex-flow: column;
        width: 85px;
		float: left; // LEGACY: to support IE (no flexbox)
		order: 4;
		text-align: center;
		flex-basis: 33.3333%;
		.error {
            margin-top: 10px;
            @include breakpoint(small) {
                text-align:left;
            }
		}
		@include breakpoint(medium-up) {
			float: none;
			text-align: left;
		}
		input {
			width: 3.7rem;
            padding-right:0.6em;
            @include breakpoint(small) {
                float: right;
            }
		}
	}
	.item-quantity-details {
		float: left; // LEGACY: to support IE (no flexbox)
		order: 3;
		text-align: center;
		flex-basis: 33.3333%;
		@include breakpoint(medium-up) {
			float: none;
			text-align: left;
		}
		.product-availability-list {
			margin: 0;
			padding: 0;
			@include breakpoint(medium-up) {
				width: 5em; //for IE
				width: max-content;
			}
			.on-order, .not-available {
				color: $error-red;
				font-size: 0.9em;
			}
		}
		.item-user-actions {
			a,
			button {
				display: block;
				font-size: 1em;
				width: 100%;
				margin: .3rem 0;
				@include breakpoint(medium-up) {
					width: auto;
				}
			}
		}
	}
    .rowcoupons {
        .item-quantity-details {
            @include breakpoint(small) {
                clear:left;
                margin-left:10px;
            }
        }
    }
	.item-price {
		text-align: center;
		flex-basis: 33.3333%;
		@include breakpoint(medium-up) {
			text-align: left;
			order: 5;
		}

        @include breakpoint(small) {
            order: 3;
        }
	}
	.price-promotion {
		.price-sales {
			color: $error-red;
		}
		.price-standard {
			margin: 0 1em 0 0;
			text-decoration: line-through;
		}
	}
	.item-total {
		flex-grow: 2; // take over item-delivery-options that it is not there
		font-size: 1rem;
		font-family: $Medium;
		order: 7;
		text-align: right;
		flex-basis: 50%;
		word-wrap: break-word;
		@include breakpoint(medium-up) {
			float: none;
			font-size: 1em;
		}
		.promo-adjustment {
			@include breakpoint(medium-up) {
				width: 100%;
				float: right;
			}
		}
        .bonus-item {
			@include breakpoint(small) {
            	font-size:0.7em;
			}
        }
	}
	.price-unadjusted {
		color: $light-gray;
		display: block;
		margin: 0 0 .42em;
		span {
			text-decoration: line-through;
		}
	}
	.price-adjusted-total {
		color: $light-gray;
		display: block;
		margin: .42em 0 0;
		span {
			color: $error-red;
		}
	}
	.gift-cert-delivery {
		color: $error-red;
	}
}

.cart-promo {
	background: url("../images/interface/ui-promo-bg-gray-diag.png") repeat scroll 0 0 transparent;
	color: $black;
	margin: 0.3em 0;
	padding: 1em;
	.bonus-item-actions {
		font-size: 1em !important;
		text-align: right;
		vertical-align: middle;
	}
}

.cart-footer {
	@extend %clearfix;
	margin: .83em 0;
}

.cart-coupon-code {
	@include breakpoint(medium-up) {
		float: right;
		width: 35%;
	}
	input {
		margin-bottom: .5rem;
		width: 50%;
		@include breakpoint(medium-up) {
			width: 62%;
		}
	}
	.error {
		padding-top: 0.4em;
	}
	button {
	    background: #fff;
        color: #0E6C99;
        padding: 10px 25px;
        float: right;
	}

    #update-cart {
        display:none;
    }
}

.cart-order-totals {
	margin: 1rem 0;
	@include breakpoint(medium-up) {
		float: right;
		margin-top: 0;
		width: 35%;
	}
}

.order-totals-table {
	td {
		padding: .3rem .3rem .3rem 0;
		font-size:12px;
		text-align: left;
		&.value,&.donations-value {
		  text-align: right;
        }

	}
	.order-totals-item {
		width: 60%;
	}

	.order-totals-value {
		width: 40%;
	}

	.order-subtotal {
		font-family: $Medium;
	}
	.order-total {
		font-size: 16px;
		font-family: $Medium;
		td {
		    font-size: 16px;
		}
	}
}
.order-value {
	white-space: nowrap;
}

.cart-actions {
	@extend %clearfix;
	.dw-apple-pay-button {
		font-size: 1.12em;
		padding: .5em 2em;
	}
    .updatecart-trigger {
        background: #fff;
        color: #0E6C99;
        padding: 8px 25px;
        float: right;
        margin: 0 10px;
        border-radius: 4px;
        border: 1px solid #0E6C99;
        font-size: 1.1em;
        font-family: $Regular;
        transition: all 0.3s ease;
        &:hover {
            border-color: transparent;
            color: #FFFFFF;
            background-color: #0D5679;
        }
    }
    @include breakpoint(small) {
        .mobile-btncontainer {
            display: flex;
            flex-flow: column;
            width: 50%;
            align-items: flex-end;
            float: right;
            fieldset {
                float: right;
                button {     
					width: 132px;
					&.dw-apple-pay-button, 
					&.cart-submit-ch {
						width: calc(50% - 3px);
					}

                }
            }
            form {
                order: 2;
            }
        }
        .updatecart-trigger {
            margin: 0 0 10px;
            order: 1;
            width: 80px;
        }
    }
}

.cart-action-checkout {
	float: right;
	@include breakpoint(medium-down) {
		width: 100%;
	}
	@include breakpoint(medium-down) {
		.cart-bottons-container {
			display: flex;
			width: 100%;

			&.bottom {
				width: 200%;
			}
		}
		.cart-submit-ch {
			font-size: 16px;
			width: calc(50% - 3px);
			margin-left: auto;
		}

		.dw-apple-pay-button {
			margin: 0 auto 0 6px;
			width: calc(50% - 3px);
			padding: 0 32px;
		}	
	}
	.button-fancy-large {
		@include breakpoint(medium-down) {
			font-size: 16px;
		}
	}
}

.cart-action-continue-shopping {
	@include breakpoint(medium-up) {
		float: right;
	}
	.button-text {
		margin-top: .2rem;
		margin-right: 1rem;
		@include breakpoint(medium-up) {
			margin-top: .6rem;
		}
	}
}

.cart-actions-top {
	margin-top: .5rem;
	margin-bottom: 1rem;
	.cart-action-continue-shopping {
		float: none;
	}
}

.cart-empty {
	@extend %clearfix;
	margin-top: 4rem;
	h1 {
		text-align: center;
	}
	.cart-action-continue-shopping {
		float: none;
		text-align: center;
		margin-bottom: 4rem;
	}
	.product-listing {
		@extend %clearfix;
		padding: 1rem 0;
		h2 {
			margin-left: 1.3rem;
		}
		.search-result-items {
			margin-top: 1em;
		}

        .grid-tile {
            padding-bottom: 0px;
            margin-bottom: 0px;
        }
	}
}


.promo-adjustment {
	color: $error-red;
}

.account-welcome {
	.welcome-message {
		font-size: 1.2em;
	}
	.not-user {
		margin: .83em 0;
	}
}

#password-reset{
	white-space: nowrap;
}

.pt_cart {
	.login-box {
		.login-oauth {
			display:none;
		}
	}

    .cart-update-msg {
        text-align: right;
        color: #c10f3a;
        margin: 10px 0px;
        font-family: $Bold;
    }
}

fieldset#addDonations {
	padding: 10px;
}

.cartDonation-Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
        @include breakpoint(medium-down) {
            flex-flow: column;
            width: 100%;
        }
        
    #addDonations{
        width: 35%;
        padding: 50px 40px;
        box-sizing: border-box;
        background: #F9FBFC;
        @include breakpoint(medium-down) {
            width: 100%;
            padding: 0px;
            margin-top: 20px;
            .donationForm {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                input {
                    width: 45%;
                }
                #add-donation {
                    width: 50%;
                    margin-top: 0px;
                    padding: 9px;
                }
            }
        }
        button {
            margin-top: 10px;
        }
    }   

    .cart-donation-Container {
        width: 65%;
        @include breakpoint(medium-down) {
            width: 100%;
        }
    }
}

.cartDonation-Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
        @include breakpoint(medium-down) {
            flex-flow: column;
            width: 100%;
        }
        
    #addDonations{
        width: 35%;
        padding: 50px 40px;
        box-sizing: border-box;
        background: #F9FBFC;
        @include breakpoint(medium-down) {
            width: 100%;
            padding: 0px;
            margin-top: 20px;
            .donationForm {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                input {
                    width: 45%;
                }
                #add-donation {
                    width: 50%;
                    margin-top: 0px;
                    padding: 9px;
                }
            }
        }
        button {
            margin-top: 10px;
        }
    }   

    .cart-donation-Container {
        width: 65%;
        @include breakpoint(medium-down) {
            width: 100%;
        }
    }
}
