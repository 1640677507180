.bottom-banner-container {
	overflow: hidden;
	.bottom-banner-cell {
		float: left;
		overflow: hidden;
		text-align: center;
	}
	.banner-cell-1 {
		width: 33%;
	}
	.banner-cell-2 {
		width: 34%;
	}
	.banner-cell-3 {
		width: 33%;
	}
}
