h1 {
	font-size: 1.5rem;
	font-family: $Regular;
	margin: .625rem 0;
	@include breakpoint(medium-up) {
		font-size: 1.75em;
		margin: 0 0 14px;
	}
}

h2 {
	font-size: 1.3em;
}

h3 {
	font-size: 1.15em;
}

#secondary h1 {
	font-size: 1.75em;
	font-family: $Regular;
	margin: 0 0 14px;
}

.primary-content {
	dl {
		clear: both;
		overflow: hidden;
		dt {
			clear: both;
			float: left;
			margin: 2% 0 0;
			min-width: 15%;
		}
		dd {
			float: left;
			margin: 2% 0 0;
			padding: 0 0 0 5%;
		}
	}
}

.col-1 {
	float: left;
	margin: 4px 0;
	width: 48%;
}

.col-2 {
	float: right;
	margin: 4px 0;
	width: 48%;
}

.content-header {
	font-size: 1.75em;
	font-family: $Regular;
	margin: 0 0 14px !important;
	span {
		font-size: 0.75em;
		font-family: $Regular;
	}
}

.section-header {
	background: url("../images/interface/icon-double-line-header.png") repeat-x scroll left top $white-alt;
	border-bottom: 1px solid $white;
	font-size: 1.1em !important;
	font-family: $Medium;
	margin: 0;
	padding: 1em;
	position: relative;
	text-transform: uppercase;
}

.section-header-note {
	font-size: 0.9em;
	font-family: $Regular;
	text-transform: none;
}

.back {
	display: block;
	margin: 0 0 10px;
}
