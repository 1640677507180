#homepage-slider {
	position: relative;
	overflow: hidden;
	width: 100%;
    .slick-dots {
        text-align:right;
        bottom:5px;
        right:5px;
        width:auto;
        li {
            margin:0;
        }
    }
}

.home-bottom-slots {
	margin: auto;
	.home-bottom-left,
	.home-bottom-center,
	.home-bottom-right {
		clear: none;
		width: 100%;
        @include breakpoint(large) {
			float: left;
			width: 32%;
			margin-right:0.515%;
			margin-left:0.515%;
			img {
				max-width:100%;
			}
		}
	}
	img:first-child{
		width: 100%;
	}
}

.pt_storefront {
    .product-listing.homepage {
        .search-result-items.slick {
            .grid-tile {
                padding-bottom: 0px;
                margin-bottom: 0px;
            }
        }
    }

	.homepage-container {
		max-width: 100%;
		margin: 0 auto;
	}
}
