body {
	background: none repeat scroll 0 0 $navigation-gray;
	color: $black;
	font-family: $Regular;
	font-size: 12px;
	@include breakpoint(medium-up) {
	background-color: $white;
	}
}

a {
	color: $navy-alt;
	outline: medium none;
	overflow: hidden;
	text-decoration: none;
	&:hover {
        color:$navy;
		text-decoration: underline;
	}
}
fieldset {
	border: 0 none;
	margin: 0;
	padding: 0;
}

table {
	width: 100%;
}

th {
	text-align: left;
}

td {
	padding: 1em;
	vertical-align: top;
}

button,
.button,
.buttonstyle,
input[type="button"],
a.button {
	background-color:$navy-alt;
	border-color: darken($navy-alt, 10%);
	border-radius: 4px;
	border: 1px solid $navy-alt;
	color: $white;
	cursor: pointer;
	display: inline-block;
	font-size: 1.1em;
	font-family: $Regular;
	padding: 0.5em 2em;
    outline:none;
	text-align: center;
	transition: all 0.3s ease;

    &.small {
        font-size:0.9em;
        padding:0.7em 1.5em;
    }
	&.producttile {
        font-size:0.9em;
        padding:0.7em 1.5em;
		background-color:$stjude-red-alt;
        border-color: $stjude-red;
        border-style: solid;
        border-width: 1px;
        border-bottom-width: 2px;
        &:hover {
            background-color: lighten($stjude-red-alt, 10%);
        }
    }
    &.large {
        background-color:$stjude-red-alt;
        border-color: $stjude-red;
        border-style: solid;
        border-width: 1px;
        border-bottom-width: 2px;
        &:hover {
            background-color: lighten($stjude-red-alt, 10%);
        }
    }
	&:hover {
		background-color:$navy;
		text-decoration: none;
	}
	&.raised {
		border-width: 0;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
		&:hover {
			color: $white;
		}
	}
	&.simple {
		background-color: transparent;
        border:1px $navy-alt solid;
		color: $navy;
		border-bottom-width:1px;
		&:hover {
			border-color:transparent;
			color: $white;
			background-color:$navy;
		}
	}
	&[disabled],
	&.disabled {
		background-color: $light-gray;
		border-color: $light-gray;
		color: $black;
		opacity: 0.4;
		&:hover {
			background-color:$light-gray;
			border-color:$light-gray;
			color:$black;
		}
	}
	a {
		color: $white;
		&:hover {
			color: $blue;
		}
	}
	i {
		&.left {
			margin-right: 0.6em;
		}
		&.right {
			margin-left: 0.6em;
		}
	}
    &.textbutton {
        background-color:transparent;
        border:none;
        color:$blue;
        padding:0;
        margin:0;
        font-size:1em;
    }
}

.button-call-to-action {
	background-color:$call-to-action-red;
	border-color: $call-to-action-red;
	border-style: solid;
	border-width: 1px;
	border-bottom-width: 2px;
	/*padding: 0.5em!important;
	margin-top: 0.5em;*/
	border-radius: 4px;
	color:$white !important;
	@include breakpoint(medium-down) {
		border-radius: 0px;
		padding: inherit;
		margin-top: inherit;
	}
	&:hover {
		background-color: $call-to-action-red-alt !important;
	}
	a {
		color: $white;
		&:hover {
			color: $blue;
		}
	}
	&.small{
		padding: 0.5em!important;
		margin-top: 0.5em;
		border-radius: 34px;
	}
}

.button-fancy-small {
}

.button-fancy-medium {
}

.button-fancy-large {
	@extend .button.large;
}

.button-fancy-producttile {
	@extend .button.producttile;
}

.button-text {
	background-color: transparent;
	border-width: 0;
	color: $blue;
	padding: 0;
	&:hover {
		background-color: transparent;
		text-decoration: underline;
	}
}

.button-full-width {
	padding-left: 0;
	padding-right: 0;
	text-align: center;
	width: 100%;
}
.slick-prev::before, .slick-next::before {
    color:$gray;
}