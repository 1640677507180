#category-level-1 {
	li {
		color: $light-gray;
		display: block;
		.refinement-link {
			background: none repeat scroll 0 0 transparent;
			padding: 0 0 0 1em;
		}
		&.expandable {
            &:before {
                font-family:"FontAwesome";
                content: "\f105"
            }
			.refinement-link {
				padding: 0 0 0 .4em;
			}
			.active {
				font-family: $Bold;
			}
            &.active {
                &:before {
                    content: "\f107"
                }
            }
		}
	}
}

#category-level-2 {
	a {
		&.active {
			color: $black;
			font-family: $Medium;
		}
		&.refinement-link {
			margin: 0 0 0 .4em;
			padding: 0;
		}
	}
}
#category-level-2 {
	padding: .5em .1em 0 .7em;
}
#category-level-3 {
	padding: .3em 0 0;
	a {
		&.refinement-link {
			font-family: $Regular;
			margin: 0 0 0 .9em;
			padding: 0;
		}
		&.active {}
	}
}

.refinement-header {
	background-color: $white-alt;
	border-top: 1px solid $white-alt;
	display: block;
	font-size: 1.1em;
	font-family: $Bold;
	margin: 0;
	padding: 1.15em .75em .75em;
	text-transform: uppercase;
}

.refinement {
	padding-bottom: .65em;
	position: relative;
	overflow: hidden;
	@include breakpoint(medium-down) {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
	// Hide Refinements on Category Landing Pages
	.pt_categorylanding & {
   		display: none;
   		&.category-refinement {
   			display: block;
   		}    	
   	}
	h3 {
		/*background-color: $white-alt;*/
		display: block;
		font-size: 1.1em;
		font-family: $Bold;
		margin: 0;
		padding: 0rem .75em .75em;
		text-transform: uppercase;
		cursor: pointer;
		.ie7 & {
			clear: both;
		}
		i {
			color: $stjude-red
		}
	}
	span {
		width: inherit;
		/*background-color: $white-alt;*/
		border-bottom: 1px solid $black-alt;
		display: block;
		font-size: 1.1em;
		font-family: $Bold;
		margin: 0 1rem 0 0.5rem;
    	padding: 5px;
		text-transform: uppercase;
	}
	i {
		color: $stjude-red
	}
	ul {
		list-style: none outside none;
		margin: 0;
		/*padding: 0 .55em 0;*/
	}
	li {
		padding: .5em;
	}
	ul {
		li {
			i {
				color: darken($white-alt, 10%);
			}
			&.selected {
				i {
					color: $stjude-red;
				}
			}
		}
		&.swatches li {
			float: left;
			padding: .16em;
			a {
				border: 2px solid darken($white-alt, 10%);
				border-radius: .5em;
				display: block;
				padding: 0.2em 0.8em;
			}
			&.selected a {
				border-color: $blue;
				font-family: $Bold;
			}
			&.unselectable a {
				background-image: url("../images/interface/icon-color-swatch-unselectable.png");
				background-position: right top;
			}
		}
		&.color li a {
			height: 23px;
			padding: 0;
			text-indent: -999em;
			width: 30px;
		}
	}
	.refinement-type-block{
		width: 90%;
    	display: flex;
    	align-items: center;
	}
	.clear-refinement {
		font-family: $Bold;
   		padding: 0 1.05em 0.4em;
	}
	.refinement-link {
		border: 0 none;
		display:inline-block;
	}
	.selected .refinement-link {
		background-position: left bottom;
	}
	.refinement-top-level {
		padding: 1em;
	}
	.scrollable {
		li {
			float: inherit;
			a {
				border: none;
				border-radius: 0px;
				display: block;
				padding: .2em .8em;
			}
		}
	}
}



.nav {
	ul {
		border: 0 none;
		list-style: none outside none;
		margin: .58em 0;
		padding: 0;
		li {
			list-style: none outside none !important;
			margin: 0 0 .58em !important;
		}
	}
	.toggle {
		border: 0 none;
		display: block;
		font-size: 1em;
		font-family: $Medium;
		margin: 1.5em 0 0.5em;
		text-transform: uppercase;
		span {
			background: none repeat scroll 0 0 transparent;
		}
	}
	.expanded {
		background-position: left -96px;
	}
}
