nav[role="navigation"] {
	@extend .clearfix;
	color: $white;
	left: -50%;
	margin-top: 0;
	position: absolute;
	width: 50%;
	top: 0;
	display: flex;
	justify-content: center;
	flex-direction: column;
	@include breakpoint(medium-up) {
		color: $black;
		left: auto;
		position: static;
		width: 100%;
		flex-direction: row;
	}
}

.menu-toggle {
	background-color: transparent;
	border: none;
	color: $white;
	display: grid;
	float: left;
	margin-left: 0.2em;
	padding: 1.6em 1em;
	@include breakpoint(medium-up) {
		display: none;
		color: $black;
	}
	&:hover {
		background-color: transparent;
	}
	.menu-icon {
		font-size: 2em;
	}
}

.menu-category {
	@extend %clearfix;
	border-bottom: 1px solid $light-gray;
	list-style: none;
	margin: 0;
	padding: 0;
	@include breakpoint(medium-up) {
		border-bottom: none;
		margin: 0 auto;
	}

	li {
		border-top: 1px solid $light-gray;
		list-style: none;
		@include breakpoint(medium-up) {
			border: none;
			float: left;
			&:hover > a {
				background-color: transparent;
			}
		}
	}
	a {
		display: inline-block;
		font-size: .9rem;
		padding: .9rem 1rem;
		overflow: visible;
		width: 100%;
		@include breakpoint(medium-up) {
			color: $black;
			font-size: 0.9rem;
			width: auto;
			padding: 1em 1.5rem;
		}
	}
}

.level-1 > li {
	@extend %clearfix;
	// override background-color for small viewport
	background-color: transparent;
	&:first-child {
		border: none;
	}
	.menu-item-toggle {
		color: $blue;
		cursor: pointer;
		float: left;
		line-height: 1.1;
		padding: 1.1rem 0.8rem;
		transition: background-color 0.3s;
		width: 2.1rem;
		&:hover {
			background-color: darken($navigation-gray, 5%);
		}
		@include breakpoint(medium-up) {
			display: none;
		}
	}
	a {
		&:hover {
			background-color: darken($navigation-gray, 5%);
			text-decoration:none;
		}
		@include breakpoint(medium-up) {
			&:hover {
				background-color: transparent;
			}
		}
	}
	&:hover {
		> a {
			@include breakpoint(medium-up) {
				background-color: $navigation-gray;
				color: $black;
			}
		}	
	}
	> a {
		float: left;
		padding-left: 0.8rem;
		&.has-sub-menu {
			width: calc(100% - 2.1rem);
		}
		@include breakpoint(medium-up) {
			float: none;
			padding: 1em 1.7em;
			width: 100%;
			&.has-sub-menu {
				width: 100%;
			}
		}
		@include breakpoint(large) {
			padding:1em 2.5em;
		}
	}
}

.level-2 {
	clear: left;
	display: none;
	> ul {
		@extend .clearfix;
		padding-bottom: .5em;
		padding-left: 2.1rem;
	}
	.level-1 > .active & {
		display: block;
	}
	@include breakpoint(medium-up) {
		background-color: $navigation-gray;
		box-shadow:0 20px 20px rgba(0, 0, 0, 0.2);
		color: $black;
		padding: 1.3em 3.5em 1.3em 2.5em;
		position: absolute;
		z-index: 10;
		.level-1 li:hover & {
			display: block;
		}
		li > a {
			border-bottom:1px solid $light-gray-mostly-transparent;
			width:100%;
		}
		a {
			color: $blue;
			&:hover {
				text-decoration: underline;
				color: $blue-alt;
			}
		}
		> ul {
			float: left;
			padding-left: 0;
			padding-bottom:0;
			overflow:hidden;
			> li {
				float: none;
				min-width: 12em;
				> a {
					padding: 1em 1rem;
					text-transform: none;
				}
			}
		}
		.menu-horizontal {
			float: none;
			> li {
				border-right: 1px solid $white-alt;
				float: left;
				padding-right: 2rem;
				&:last-child {
					border-right: none;
				}
			}

		}
		.banner {
			float: left;
		}
	}
	.banner {
		display: none;
		@include breakpoint(medium-up) {
			display: block;
		}
		img {
			vertical-align: top;
		}
	}
}

.level-3 {
	display: none;
	padding-left: 2em;
	.level-2 .active & {
		display: block;
	}
	@include breakpoint(medium-up) {
		display: block;
		a {
			font-size: 1.2em;
		}
		li {
			float: none;
		}
	}
}
