.js .simple-submit {
	display: none;
}

legend {
	background: url("../images/interface/icon-double-line-header.png") repeat-x scroll left top $white-alt;
	border-bottom: 1em solid $white;
	font-size: 1.1em;
	font-family: $Medium;
	margin: 0;
	padding: 1.16em 2% .83em;
	position: relative;
	text-transform: uppercase;
	width: 96%;
	.ie7 & {
		width: 94%;
	}
	span {
		font-size: 12px;
		font-family: $Regular;
		position: relative;
		text-transform: none;
		padding: 0 .5em;
		em {
			color: $black;
		}
	}
}

label {
	display: block;
	font-size: 1.1em;
	font-family: $Medium;
	margin-bottom: .3rem;
	.form-horizontal & {
		float: left;
		margin-top: .5rem;
		width: 100%;
	}
	.label-inline & {
		display: inline;
		float: none;
		font-size: 1em;
		font-family: $Regular;
		margin-top: 0;
		text-align: left;
		width: auto;
	}
	.label-above & {
		margin-bottom: .3rem;
		width: auto;
	}
}

.form-label-text {
	float: left;
	font-size: 1.1em;
	font-family: $Medium;
	padding: 0.75em 0 0;
	text-align: right;
	width: 25%;
}

.form-row {
	@extend %clearfix;
	margin: .5rem 0 1rem;
    select {
        margin-top:1em;
    }
}

.form-row-button {
	clear: left;
	.form-horizontal & {
		@include breakpoint(medium-up) {
			margin-left: 27%;
			width: 62%;
		}
	}
}

.field-wrapper {
	box-sizing: border-box;
	margin-bottom: .5rem;
	width: 100%;
	.form-horizontal & {
		float: left;
		width: 76%;
	}
	.label-inline & {
		float: left;
		padding-left: 0;
		width: auto;
	}
	.label-above & {
		float: none;
		padding-left: 0;
		width: auto;
	}
}

input[type="text"],
input[type="tel"],
input[type="number"],
input[type="password"],
input[type="date"]
textarea {
	border: 1px solid $light-gray-partial-transparent;
	box-sizing: border-box;
	height: 2.8rem;
	border-radius:3px;
	padding: 1em 1.2em;
	text-indent: 0;
	width: 100%;
}

select {
	border: 1px solid $light-gray-partial-transparent;
	background-color: #fff; // set background color to make height work
	width: 100%;
	height: 2rem;
}

input[type="checkbox"],
input[type="radio"] {
	margin: 1em 1% 1em 2%;
	.label-inline & {
		margin: 0 .3rem 0 0;
	}
}

.form-caption {
	clear: left;
	width: auto;
	.label-above & {
		margin-left: 0;
	}
	.form-indent & {
		margin-left: 0;
	}
}

.error-message {
	border: 2px solid $error-red;
	background-color: $light-gray-mostly-transparent;
	padding: 0.7em 0.5em;
	text-indent: 0;
	width: 49.5966%;
}

.error {
	background-color: $light-gray-mostly-transparent;
	border-color: $error-red;
	color: $error-red;
}

span.error,
div.error {
	background: none;
}

.error-form {
	background-color: $light-gray-mostly-transparent;
	border: 1px solid $error-red;
	border-radius: .5em;
	color: $error-red;
	margin: 0.5em 0;
	padding: 0.8em;
}

.form-inline {
	form {
		padding: 0 0 2em;
		width: 98%;
	}
	label {
		padding: 0 0 0.75em;
		text-transform: uppercase;
		width: auto;
	}
	input[type="text"], select {
		margin-left: 0;
		width: 95%;
	}
	button {
		padding-left: 0;
		padding-right: 0;
		width: 100%;
	}
}

.row-inline {
	display: inline;
	label {
		width: auto;
	}
}

// Required Indicator and Text
.dialog-required {
	display: inline;
	position: relative;
	padding: 0 .5em;
	em {
		color: $error-red;
		font-size: .65rem;
		font-family: $Regular;
		text-transform: uppercase;
	}
}
.required-indicator {
	color: $error-red;
	font-size: 1rem;
	padding: 0;
}

.form-field-tooltip {
	float: left;
	margin-left: 3%;
	padding-top: .75em;
	width: 20%;
}
.input_custom{
	.form-row{
		margin-top: 0 !important;
	}
	.form-row select{
		margin-top: 0 !important;
	}
	label{
		margin-top: 0;
	}
	.col-2 {
	  float:none; 
	  display:inline-block;
	  width:35.6%;
	  .field-wrapper {
		  width:100%;
	  }
  }
}

@media only screen and (max-width: 600px) {
	.input_custom {
		.col-2 {
			width: 100%;
			margin-left: 0 !important;
			margin-right: 0 !important;

			.field-wrapper {
				width: 100%;
			}
		}
	}
}