@media screen and (max-width: 767px) {
    .breadcrumb {
        padding: 1.3em 20px;
    }
    
    img {
        max-width: 100%;
    }
    .tooltip {
        font-size: .9em;
    }
    .form-field-tooltip {
        /*float: none;*/
        margin: 0 0 10px 5px;
        width: auto;
    }
    .field-wrapper {
        .form-horizontal & {
            width: 100%;
        }
    }
    .ui-dialog {
        max-width: 98%;
        &:not(.size-chart-modal) {
            .ui-dialog-content {
                height: auto !important;
                overflow: visible;
            }
        }
    }
    .col-1,
    .col-2 {
        float: none;
        width: 100%;
    }
    .scrollable {
        height: auto;
        li {
            float: left;
            a {
                border: 1px solid #d9d9d9;
                border-radius: 6px;
                display: block;
                padding: .2em .8em;
            }
        }
    }
    #header {
        height: auto;
        padding: 1% 1% 30px;
        .primary-logo {
            float: left;
            position: relative;
            top: auto;
            width: 65%;
            img {
                max-width: 100%;
            }
        }
    }
    .mini-cart-products {
        max-height: 100%;
    }
    .mini-cart-content {
        max-width: 220px;
    }
    #quickviewbutton,
    .quickviewbutton {
        display: none !important;
    }
    ul.search-result-items .grid-tile {
        margin: 2%;
        width: 92%;
            &.col-2 {
                width: 46%!important;
            }
            &.col-3 {
                width: 26%!important;
            }
            &.col-4 {
                width: 48%!important;
                margin: 0 0 2% 0!important;
                padding: 1%!important;
            }
            &.col-5 {
                width: 16%!important;
            }
            &.col-6 {
                width: 12%!important;
            }
        
    }
    .search-result-options {
        margin:  0;
        padding: 5px 0;
        
        /*justify-content: space-between;
        flex-flow: column;
        flex-wrap: wrap;*/
        .sort-by {
            select {
                display: inline-block;
                /*width: 55%;*/
            }
        }
        .items-per-page {
            display: none;
        }
        .pagination {
            margin: 0;
            .results-hits {
                display: none;
            }
        }
    }
    .product-tile {
        height: auto;
    }
    .wide-tiles .product-tile {
        .product-image {
            width: 41%;
        }
        .product-name,
        .product-pricing,
        .product-promo,
        .pr_stars,
        .product-swatches {
            float: right;
        }
        .product-name h2 {
            margin-top: 0px;
        }
    }
    .product-tile .product-swatches .swatch img {
        height: 2em;
        width: 2em;
    }
    .product-listing-1x4 ul.search-result-items .grid-tile {
        margin: 2%;
        width: 92%;
    }
    .product-compare,
    .compareitems {
        display: none !important;
    }
    .pt_product-details {
        .primary-content {
            padding-bottom: 68px;
        }
        .breadcrumb .last {
            display: none;
        }
    }
    #product-nav-container {
        width: 100%;
        position: static;
        div {
            width: 60px;
            &.product-next {
                float: right;
            }  
        }
        img {
            display:none;
        }
        span:first-child {       
            height: auto;
        }
    }
    .sizinginformation {
        padding: 0;
        th {
            font-weight: normal;
            padding: 0 0 0 2px;
        }
        td {
            background: #ffffff;
            padding: 5px;
            text-align: center;
        }
        .sizechart {
            padding: 0 !important;
        }
    }
    .ui-dialog .ui-dialog-content {
        padding: .25em;
    }
    .pt_cart #secondary {
        display: none;
    }
    .checkout-progress-indicator {
        clear: both;
        font-size: .8em;
        //margin-bottom: 3%;
        position: relative;
        overflow: hidden;
        text-align: left;
        top: auto;
        // div {
        //     padding: 0 2%;
        // }
    }
    .order-summary-footer h2 {
        width: 50%;
    }
    .place-order-totals .order-totals-table {
        width: 100%;
    }
    .payment-method-options {
        padding: 0;
        .form-row {
            width: 100%;
            label {
                float: left;
                margin: 0;
                padding: 0 0 0 1em;
                width: 150px;
            }
            .input-radio {
                margin: 0;
            }
        }
    }
    .pt_order-confirmation {
        .item-list {
            th {
                display: none;
            }
            td {
                float: left;
                padding: 1em 0;
                width: 98%;
            }
            .order-totals-table td {
                float: left;
                width: 48%;
            }
        }
        .order-shipment-table {
            th {
                display: none;
            }
            td {
                font-size: .9em;
                box-sizing: border-box;
                float: left;
            }
            .line-item-details {
                width: 70%;
            }
            .line-item-quantity {
                width: 10%;
            }
            .line-item-price {
                width: 20%;
            }
            .order-shipment-details {
                border: none;
                width: 100%;
            }
            .scrollable {
                height: auto;
                li {
                    float: left;
                    a {
                        border: 1px solid #d9d9d9;
                        border-radius: 6px;
                        display: block;
                        padding: .2em .8em;
                    }
                }
            }
            #header {
                height: auto;
                padding: 1% 1% 30px;
                .primary-logo {
                    float: left;
                    position: relative;
                    top: auto;
                    width: 65%;
                    img {
                        max-width: 100%;
                    }
                }
            }
            .mini-cart-products {
                 max-height: 100%;
            }
            .mini-cart-content {
                max-width: 220px;
            }
            #quickviewbutton,
            .quickviewbutton {
                display: none !important;
            }
            ul.search-result-items .grid-tile {
                margin: 2%;
                width: 92%;
            }
            .product-tile {
                height: auto !important;
            }
            .wide-tiles .product-tile {
                .product-image {
                    width: 41%;
                }
                .product-name,
                .product-pricing,
                .product-promo,
                .pr_stars,
                .product-swatches {
                    float: right;
                }
                .product-name h2 {
                    margin-top: 0px;
                }
            }
            .product-tile .product-swatches .swatch img {
                height: 2em;
                width: 2em;
            }
            .product-listing-1x4 ul.search-result-items .grid-tile {
                margin: 2%;
                width: 92%;
            }
            .product-compare,
            .compareitems {
                display: none !important;
            }
            .pt_product-details {
                .primary-content {
                    padding-bottom: 68px;
                }
                .breadcrumb .last {
                    display: none;
                }
            }
            #product-nav-container {
                width: 100%;
            }
        }
        .create-new-registry {
            overflow: hidden;
            p {
                display: none;
            }
        }
        .checkout-progress-indicator {
            clear: both;
            font-size: .8em;
            margin-bottom: 3%;
            position: relative;
            overflow: hidden;
            text-align: left;
            top: auto;
            div {
                padding: 0 2%;
            }
        }
        .order-summary-footer h2 {
            width: 50%;
        }
        .place-order-totals .order-totals-table {
            width: 100%;
        }
        .payment-method-options {
            padding: 0;
            .form-row {
                width: 100%;
                label {
                    float: left;
                    margin: 0;
                    padding: 0 0 0 1em;
                    width: 150px;
                }
                .input-radio {
                    margin: 0;
                }
            }
        }
        .pt_order-confirmation {
            .item-list {
                th {
                    display: none;
                }
                td {
                    float: left;
                    padding: 1em 0;
                    width: 98%;
                }
                .order-totals-table td {
                    float: left;
                    width: 48%;
                }
            }
            .order-shipment-table {
                th {
                    display: none;
                }
                td {
                    font-size: .9em;
                    box-sizing: border-box;
                    float: left;
                }
                .line-item-details {
                    width: 70%;
                }
                .line-item-quantity {
                    width: 10%;
                }
                .line-item-price {
                    width: 20%;
                }
                .order-shipment-details {
                    border: none;
                    width: 100%;
                }
            }
        }
        .order-history-header button {
            font-size: 1em;
            padding: .25em;
        }
        .page-content-tab-navigaton button {
            width: 100%;
        }
        .pt_gift-registry {
            .add-gift-cert {
                p {
                    width: 100%;
                }
            }
            .create-new-registry {
                overflow: hidden;
                p {
                    display: none;
                }
            }
            .item-list {
                width: 98%;
                th {
                    display: none;
                }
            }
        }
        .order-shipment-table .section-header {
            font-size: .9em !important;
            padding: .3em;
        }
        table.item-list .section-header,
        .order-shipment-table .section-header {
            background-image: none;
        }
        table.item-list {
            .item-image,
            .item-details,
            .item-availability,
            .item-dashboard {
                display: inline-block;
            }
            .item-image {
                width: 30%;
            }
            .item-details {
                width: 50%;
            }
            .item-availability,
            .item-dashboard {
                width: 97%;
            }
            .delete-registry {
                text-align: center;
            }
        }
        .list-table-header {
            padding: 1em !important;
            .button {
                display: block;
                margin: 20px 0;
                width: 196px;
            }
            p {
                display: none;
            }
            button {
                display: block;
                width: 260px;
            }
        }
        .home-bottom-left,
        .home-bottom-center {
            width: 100% !important;
        }
        .home-bottom-right {
            clear: both;
            float: none;
            width: 100% !important;
            .product-tile {
                min-height: 0;
            }
        }
        .product-tile .product-image {
            height: auto;
        }
        #email-alert-signup {
            margin: 0;
            width: 100%;
            label {
                text-align: center;
                width: 100%;
            }
            input [type="text"] {
                float: none;
                margin: 0;
                width: 50%;
            }
            input[type="image"] {
                float: none;
                vertical-align: bottom;
            }
        }
        .facebook-registration {
            left: -3px;
            position: relative;
            width: 100.7% !important;
            iframe {
                position: relative;
                width: 100.7% !important;
            }
        }
    }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
    .product-tile .pr_stars {
        left: 40px;
    }
    .wide-tiles .product-tile {
        .product-name h2 {
            margin-top: 10px;
        }
        .product-image {
            width: 38%;
        }
    }
    .facebook-registration {
        left: 0px;
        position: relative;
        width: 100% !important;
        .fb_iframe_widget {
            position: relative;
            width: 100% !important;
            span {
                position: relative;
                width: 100% !important;
                iframe {
                    position: relative;
                    width: 100% !important;
                }
            }
        }
    }
    #preferred-store-panel input[type="text"] {
        width: 49.5966%;
    }
}

@media screen and (min-width: 768px) and (max-width: 959px) {
    #secondary {
        width: 171px;
    }
    .primary-content {
        // width: 588px;
        width: 562px;
    }
    .primary-focus {
        .primary-content {
            width: 540px;
        }
        #secondary {
            width: 190px;
        }
    }
    .html-slot-container img,
    .bottom-banner-cell img {
        max-width: 100%;
    }
    .ui-dialog {
        max-width: 98%;
        &:not(.size-chart-modal) {
            .ui-dialog-content {
                height: auto !important;
                overflow: visible;
            }
        }
    }
    .scrollable {
        height: auto;
    }
    .mini-cart-total-label {
        display: none;
    }
    .mini-cart-products {
        max-height: 100%;
    }
    ul.search-result-items {
        .new-row {
            clear: none;
        }
        .grid-tile {
            width: 41.5%;
        }
    }
    table .section-header {
        font-size: 1em !important;
    }
    #compare-table td .product-tile {
        width: 79px;
    }
    .pt_gift-registry .add-gift-cert p {
        width: auto;
    }
    #cart-table {
        .item-user-actions {
            width: 72px;
        }
        .item-total .promo-adjustment {
            width: 77px;
        }
    }
    .home-bottom-right .product-tile {
        min-height: 0;
    }
    #email-alert-signup {
        margin: 0;
        width: 100%;
        label {
            float: none;
            display: block;
            text-align: center;
            width: 100%;
        }
        input[type="text"] {
            width: 73%;
        }
    }
    .facebook-registration {
        left: 0px;
        position: relative;
        width: 100% !important;
        .fb_iframe_widget {
            position: relative;
            width: 100% !important;
            span {
                position: relative;
                width: 100% !important;
                iframe {
                    position: relative;
                    width: 100% !important;
                }
            }
        }
    }
    .mini-cart-item-word{
        span{
            display:none;
        }
    }
}

@media screen and (max-width: 480px) {
    #preferred-store-panel input[type="text"] {
        width: 49.5966%;
    }
}
