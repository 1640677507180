.menu-utility-user {
	margin: 0;
	padding: 0;
	@include breakpoint(medium-up) {
		position: absolute;
		right:5em;
		top: 0.55em;
		border-right:1px solid $gray;
		margin-right:0.3em;
		padding: 0.3em 0.7em;
	}
	@include breakpoint(large) {
		right: 9.7em;
	}
	li {
		border-top: 1px solid darken($black, 10%);
		list-style: none;
		padding: 0.8em 1rem;
		&:first-child {
			border: none;
		}
		i {
			/*font-size: 1.5em;*/
			padding: 0 8px 0 0;
		}
		@include breakpoint(medium-up) {
			background-color: transparent;
			border-color: transparent;
			float: left;
			margin: 0;
			padding: 0.1em 0.7em;
			text-align: center;
			&:first-child {
				border: none;
			}
		}
		a {
			color: $black;
			text-decoration: none;
			&:active {
				color: $blue;
			}
			@include breakpoint(medium-up) {
				/*color: $light-gray;*/
				transition: color .5s ease;
				&:hover {
					color: $blue;
					text-decoration: none;
				}
				i {
					/*display: none;
					font-size: 3em;*/
					padding: 0;
				}
				span {
					display: block;
				}
			}
		}
	}
	.user-info {
		@include breakpoint(medium-up) {
			position: relative;
			transition: color .5s ease;
			&.active {
				.user-login {
					color: $blue;
				}
				.user-panel {
					opacity: 1;
					visibility: visible;
				}
			}
		}
		.user-panel {
			box-sizing: content-box; //See https://code.google.com/p/chromium/issues/detail?can=2&q=max-content&colspec=ID%20Pri%20M%20Stars%20ReleaseBlock%20Cr%20Status%20Owner%20Summary%20OS%20Modified&id=522191&thanks=522191&ts=1439925077
			margin-top: .5rem;
			text-align: left;
			transition: all .3s ease;
			width: 10rem; //For IE
			width: max-content;
			h3 {
				display: none;
				margin: 0;
				padding-bottom: .7em;
				text-transform: uppercase;
				@include breakpoint(medium-up) {
					display: block;
				}
			}
			@include breakpoint(medium-up) {
				background-color: $white;
				border: 1px solid $white-alt;
				border-radius: 5px;
				box-shadow: 0 2px 5px 0 $tan;
				margin-top: 0;
				opacity: 0;
				padding: 1em;
				position: absolute;
				right: -1.5rem;
				visibility: hidden;
				z-index: 20;
			}
			.user-links {
				padding-left: 0.5rem;
				text-align: center;
				@include breakpoint(medium-up) {
					padding-left: 0;
				}
			}
			a {
				color: $blue;
				display: block;
				padding: .5rem 0;
				@include breakpoint(medium-up) {
					padding: 0;
					margin-bottom: .5rem;
				}
			}
			.user-logout {
				color: $white;
				margin-top: .5rem;
				padding: 0.5em 2em;
				text-transform: uppercase;
				&:hover {
					color: $blue;
				}
			}
		}
	}
}
